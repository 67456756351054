import React from 'react';
import { Link } from 'react-router-dom';
import RoutePath from '../../../constants/route-paths';
import Theming from '../../../constants/theming';
import { genRoutePath } from '../../../helpers/route_helpers';
import PromiseLoader from '../../templates/PromiseLoader';
import TeamMemberService from '../../../services/team_member_service';
import { TeamMemberType } from '../../../models/team_member';
import ProfileImage from './ProfileImage';

const routeToMemberPage = (memberId: string) => genRoutePath([RoutePath.teammembers, memberId]);

class TeamMembers extends PromiseLoader<TeamMemberType[]> {
    promise() {
        return TeamMemberService.getTeamMembersByType();
    }

    dataComponent(data: TeamMemberType[]) {
        return (
            <div className="container py-5 my-5">
                {
                    data.map((val, idx) => (
                        <section key={idx}>
                            <h2 className="h2-responsive"><span style={{ color: Theming.backgroundColourDark }}>{val.name}</span> Members</h2>
                            <hr className="my-3"></hr>
                            <div className="row mb-4 wow fadeIn">
                                {val.team_members.map((member) => {
                                    return (
                                        <div className="col-lg-3 col-md-4 col-sm-6 mb-4" key={member.id}>
                                            <div className="card">
                                                <div className="view overlay">
                                                    <ProfileImage picture={member.picture} objectFit="cover" />
                                                    <Link to={routeToMemberPage(member.id)}>
                                                        <div className="mask rgba-white-slight"></div>
                                                    </Link>
                                                </div>
                                                <div className="card-body text-center">
                                                    <h4 className="card-title">{member.name}</h4>
                                                    <p className="card-text">{member.sham_designation === undefined ? member.designation : member.sham_designation}</p>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </section>
                    ))
                }
            </div>
        );
    }
}

export default TeamMembers;