import React, { Component } from 'react'
import { Redirect } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import RoutePath from '../../../constants/route-paths';
import Theming from '../../../constants/theming'
import * as auth from "../../../firebase/auth";
import DefaultProps from '../../../models/default_props';
import { AppContextSchema, withAppContext } from '../../app-context';

interface InterfaceProps extends DefaultProps, AppContextSchema {
  email?: string;
  error?: any;
}

interface InterfaceState {
  email: string;
  error: any;
  redirect: any;
}

class ForgotPassword extends Component<InterfaceProps, InterfaceState> {
  componentDidUpdate() {
    if (this.props.currentUser)
      this.setState({ redirect: RoutePath.home });
  }
  private static INITIAL_STATE = {
    email: "",
    error: null,
    redirect: null
  };

  private static propKey(propertyName: string, value: any): object {
    return { [propertyName]: value };
  }

  constructor(props: InterfaceProps) {
    super(props);

    this.state = { ...ForgotPassword.INITIAL_STATE };
  }

  private onSubmit = (event: any) => {
    const { email } = this.state;

    auth.doPasswordReset(email)
      .then(() => {
        this.props.history.push(RoutePath.home);
      })
      .catch((error: any) => {
        this.setState(ForgotPassword.propKey("error", error));
      });

    event.preventDefault();
  };

  private clearFields = () => {
    this.setState(ForgotPassword.propKey("error", ''));
    this.setState(ForgotPassword.propKey("email", ''));
  }

  showErrorMessage(message: any) {
    return (
      <div className="alert alert-warning alert-dismissible fade show" role="alert">
        {message}
        <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={this.clearFields}>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    );
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />
    }
    const { email, error } = this.state;
    const isInvalid = email === "";
    return (
      <div className="d-flex justify-content-center py-5 my-5" >
        <div className="card" style={{ minWidth: "350px", maxWidth: "350px" }}>
          <h5 className="card-header text-uppercase text-center py-4" style={{ backgroundColor: Theming.backgroundColourLight, color: Theming.textColourLight }}>
            <strong>Forgot Password</strong>
          </h5>
          <div className="card-body px-lg-5 pt-0">
            <form className="text-center" onSubmit={event => this.onSubmit(event)}>
              <div className="md-form">
                <input type="email" id="materialForgotPassword" className="form-control" style={{ color: Theming.textColourDark }} value={email} onChange={event => this.setStateWithEvent(event, "email")} />
                <label htmlFor="materialForgotPassword" style={{ color: Theming.textColourDark }}>Enter Email</label>
              </div>
              <button className="btn btn-block my-4 waves-effect z-depth-0" type="submit" disabled={isInvalid} style={{ borderColor: Theming.backgroundColourDark }}>Submit</button>
              {error && this.showErrorMessage(error.message)}
              <ToastContainer />
            </form>
          </div>
        </div>
      </div>
    )
  }
  private setStateWithEvent(event: any, columnType: string): void {
    this.setState(
      ForgotPassword.propKey(columnType, (event.target as any).value)
    );
  }
}

export default withAppContext(ForgotPassword);