import { NewsletterModel, NewslettersResponse } from '../models/newsletter';
import { QueryParams } from '../models/params_model';
import CoreService from './core_service';
import RuntimeCache from './runtime_cache_service';

class NewsletterService extends CoreService {
	static _instance: NewsletterService;
	static getInstance = () => {
		if (!NewsletterService._instance) {
			return NewsletterService._instance = new NewsletterService();
		}
		return NewsletterService._instance;
	};

	cacheService: RuntimeCache;

	constructor() {
		super();
		this.cacheService = new RuntimeCache('newsletters', false);
	}

	getNewsletters = async (page: number, _limit: number, find?: string) => {
		return this.cacheService.getElseSet<NewslettersResponse>(
			page.toString() + _limit.toString() + find || '',
			async () => {
				const _start = _limit * (page - 1);
				const params: QueryParams = { _start, _limit, _sort: 'published_at:desc' };
				if (find)
					params._q = find;

				const newslettersRes = await this._strapi.getEntriesWithCount<NewsletterModel>('newsletters', {
					params
				});
				const totalPages = newslettersRes.totalCount ? Math.ceil(newslettersRes.totalCount / _limit) : 1;

				newslettersRes.data.forEach(e => {
					this.cacheService.set(e.id, e);
				});

				return {
					page,
					totalPages,
					pageData: newslettersRes.data
				};
			}
		);
	};

	getNewsletterById = (id: string) => this.cacheService.getElseSet<NewsletterModel>(id, () => {
		return this._strapi.getEntry('newsletters', id) as Promise<NewsletterModel>;
	});
}

export default NewsletterService.getInstance();
