import Strapi from 'strapi-sdk-javascript';
import { AxiosRequestConfig } from 'axios';

export default class StrapiService extends Strapi {
    getEntriesWithCount: <T = Object>(contentTypePluralized: string, config?: AxiosRequestConfig) => Promise<{
        totalCount: number;
        data: T[];
    }> = (contentTypePluralized, config) => {
        return this.request('GET', contentTypePluralized + 'WithCount', config);
    };
}
