import * as React from "react";
import { withRouter } from "react-router-dom";
import LoginForm from "./loginForm";

const SignInComponent = ({ history, location }: { [key: string]: any }) => (
    <LoginForm history={history} location={location} />
);

const Login = withRouter(SignInComponent);

export default Login;