import React, { useState } from "react";
import Carousel from 'react-bootstrap/Carousel';
import Theming from '../../../constants/theming';
import MediaHelpers from '../../../helpers/media_file_helpers';
import { Image } from '../../../models/image';
import CarouselService from '../../../services/carousel_service';
import PlaceholderImgProps from '../../templates/PlaceholderImg';

const CarouselPage = (props: { carousalItemList: Image[]; }) => {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex: number) => {
    setIndex(selectedIndex);
  };

  return (
    <Carousel activeIndex={index} onSelect={handleSelect}>
      {props.carousalItemList.map((item) => {
        return (<Carousel.Item key={item.id}>
          <PlaceholderImgProps
            className="d-block w-100"
            src={MediaHelpers.resolveMediaUrl(item.url)}
            blurhash={item.formats.blurhash}
            alt={item.alternativeText}
            style={{ maxHeight: "720px" }}
          />
          <Carousel.Caption>
            <h2 className="text-capitalize d-none d-sm-block" style={{ color: Theming.textColourDark }}>Society for Heritage, Archaeology & Management</h2>
            <h5 className="d-none d-sm-block" style={{ color: Theming.textColourDark }}>Connecting the Heritage & Culture accross the Globe</h5>
          </Carousel.Caption>
        </Carousel.Item>);
      })}
    </Carousel>
  );
};

class CarouselLoader extends React.Component {
  render() {
    return (
      <div className="d-block w-100" style={{ maxHeight: "720px" }}>
        <CarouselPage carousalItemList={CarouselService.homeCarousel().images} />
      </div>
    );
  }
}

export default CarouselLoader;