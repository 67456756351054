import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import Theming from '../../../constants/theming';
import emailjs from 'emailjs-com';
import { toast } from 'react-toastify'

const Contact = () => {
  const [modalShow, setModalShow] = useState(false);

  const ContactUsModal = (props: any) => {
    const onFormSubmit = (e: any) => {
      e.preventDefault()
      emailjs.send(process.env.REACT_APP_EMAILJS_SERVICE_ID || '', process.env.REACT_APP_EMAILJS_TEMPLATE_ID || '', { from_name: e.target['name'].value, reply_to: e.target['email'].value, phn_no: e.target['phnno'].value, message: e.target['description'].value }, process.env.REACT_APP_EMAILJS_USER_ID || '').then(() => {
        toast.success('Email Successfully Sent!')
        toggleModal(false)
      })
    }
    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="text-uppercase" style={{ backgroundColor: Theming.backgroundColourLight, color: Theming.textColourLight }} closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Contact Us
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={onFormSubmit}>
            <div className="form-group">
              <label htmlFor="contactFormName">Full Name</label>
              <input type="text" name="name" className="form-control" id="contactFormName" placeholder="Enter Your Name" required />
            </div>
            <div className="form-group">
              <label htmlFor="contactFormEmail">Email address</label>
              <input type="email" name="email" className="form-control" id="contactFormEmail" aria-describedby="emailHelp" placeholder="Enter Your Email" required />
              <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small>
            </div>
            <div className="form-group">
              <label htmlFor="contactFormPhnNo">Phone Number</label>
              <input type="number" name="phnno" className="form-control" id="contactFormPhnNo" aria-describedby="phnNpHelp" placeholder="Enter Your Phone Number" min={1000000000} max={9999999999} required />
              <small id="phnNoHelp" className="form-text text-muted">We'll never share your phone number with anyone else.</small>
            </div>
            <div className="form-group">
              <label htmlFor="contactFormDescription">Description</label>
              <textarea name="description" className="form-control" id="contactFormDescription" placeholder="Describe your issues, queries, etc here" rows={3} required></textarea>
            </div>
            <div className="text-center">
              <button type="submit" className="btn" style={{ borderColor: Theming.textColourDark, color: Theming.textColourDark, backgroundColor: Theming.backgroundColourDark }}><i className="far fa-paper-plane"></i> Send</button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    );
  }

  const toggleModal = (toggle: boolean) => {
    setModalShow(toggle)
  }

  return (
    <>
      <button type="button" className="btn py-2 px-3" onClick={() => toggleModal(true)} style={{ position: "fixed", bottom: '20px', right: "20px", zIndex: 9999, borderColor: Theming.textColourDark, color: Theming.textColourDark, backgroundColor: Theming.backgroundColourDark }}>
        <i className="fas fa-phone"></i> Contact Us
      </button>

      <ContactUsModal
        show={modalShow}
        onHide={() => toggleModal(false)}
      />
    </>
  );
}

export default Contact;