import React, { useState, useCallback } from "react";
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import { Image } from '../../../models/image';
import MediaHelpers from '../../../helpers/media_file_helpers';
import { getLargeUrl } from '../../../helpers/image_helpers';

const GalleryBuilder = (props: { images: Image[]; }) => {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((_, { index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const photos = props.images.map(img => ({
    src: MediaHelpers.resolveMediaUrl(img.formats.small.url),
    width: img.width,
    height: img.height,
    alt: img.alternativeText,
    key: img.hash + img.caption
  }));

  return (
    <div>
      <Gallery photos={photos} onClick={openLightbox} />
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={props.images.map(img => ({
                source: {
                  regular: getLargeUrl(img),
                  fullscreen: getLargeUrl(img),
                  download: img.url,
                },
                caption: img.caption
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </div>
  );
};

export default GalleryBuilder;
