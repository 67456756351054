import React, { CSSProperties } from "react";
import Theming from "../../constants/theming";
import { rejected } from "../../helpers/promise_helpers";
import AsyncSnapshot from "../../models/async_snapshot";
import PromiseBuilder from "./promise_builder";

const centerStyle: CSSProperties = { height: "60vh"};

export default class PromiseLoader<T = any, P = {}, S = {}, SS = any> extends React.Component<P, S, SS> {
	snapshot?: AsyncSnapshot;

	promise() {
		return rejected<T>(new Error("'promise()' is not overriden in sub class of PromiseLoader"));
	}

	setPromise(newPromise?: () => Promise<T>) {
		this.promise = newPromise ? newPromise : this.promise;
		this.setState({});
	}

	loadingComponent() {
		return (
			<div className="d-flex justify-content-center align-items-center" style={centerStyle}>
				<div className="spinner-grow text-dark" role="status" style={{ backgroundColor: Theming.backgroundColourDark }}>
					<span className="sr-only">Loading...</span>
				</div>
			</div>
		);
	}

	errorComponent(error: any) {
		return (
			<div style={centerStyle}>{error.toString()}</div>
		);
	}

	dataComponent(data?: T): JSX.Element {
		return (
			<div style={centerStyle}>
				{new Error("'dataComponent(data)' is not overriden in sub class of PromiseLoader").toString()}
			</div>
		);
	}

	render() {
		return PromiseBuilder(
			this.promise(),
			(snap) => {
				this.snapshot = snap;
				switch (snap.status) {
					case "loading":
						return this.loadingComponent();

					case "complete":
						return this.dataComponent(snap.data);

					case "failed":
					default:
						return this.errorComponent(snap.error);
				}
			}
		);
	}
}