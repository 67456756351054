import StrapiService from './strapi_service';

export default class CoreService {
	static token?: string;

	_strapi: StrapiService;

	constructor() {
		this._strapi = new StrapiService(
			process.env.REACT_APP_API_ENDPOINT || 'http://localhost:1337',
			{
				cookie: {
					key: 'sham-strapi',
					options: {
					}
				}
			}
		);
	}

	isSignedIn = () => CoreService.token ? true : false;

	_setToken(token?: string) {
		CoreService.token = token;
	}

	_getToken = () => CoreService.token;

	_withAuthHeader(headers?: { [key: string]: string; }) {
		headers = headers || {};

		if (CoreService.token)
			headers.Authorization = `Bearer ${CoreService.token}`;

		return headers;
	}
}