import React from 'react';
import { Link } from 'react-router-dom';
import RoutePath from '../../../constants/route-paths';
import Theming from '../../../constants/theming';
import DefaultProps from '../../../models/default_props';
import { EventModel, EventsResponse } from '../../../models/event';
import EventService from '../../../services/event_service';
import PromiseLoader from '../../templates/PromiseLoader';
import EventCard from '../Events/event_card';

class EventSection extends PromiseLoader<EventsResponse, DefaultProps> {
    async promise() {
        return EventService.getEvents(1, 3);
    }

    render() {
        return (
            <main className="my-5 py-5">
                <div className="container" style={{ color: Theming.textColourDark }}>
                    <div className="font-weight-bold mb-4 text-center">
                        <div className="dark-grey-text">
                            <h2 style={{ display: "inline", color: Theming.textColourDark }}>EVENTS</h2>
                            <p style={{ display: "inline", paddingTop: "10px" }} className="float-right">
                                <Link to={RoutePath.events} style={{ textDecoration: "none" }}>
                                    All Events
                                    <i className="fas fa-long-arrow-alt-right"></i>
                                </Link>
                            </p>
                        </div>
                    </div>
                    <hr className="my-3"></hr>
                    {super.render()}
                </div>
            </main>
        );
    }

    loadingComponent() {
        return (
            <div className="d-flex justify-content-center">
                <div className="spinner-grow text-dark" role="status" style={{ backgroundColor: Theming.backgroundColourDark }}>
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
        );
    }

    errorComponent(error: any) {
        return (
            <div className="d-flex justify-content-center">{error.toString()}</div>
        );
    }

    dataComponent(eventsJson: EventsResponse) {
        return (
            <section className="text-center">
                <div className="row mb-4 wow fadeIn">
                    {this.displayEvents(eventsJson.pageData)}
                </div>
            </section>
        );
    }

    displayEvents = (events: EventModel[]) => {
        return events.map(e => (
            <EventCard event={e} key={e.id}/>
        ));
    };
}

export default EventSection;