import React, { Component } from 'react'
import CarouselPage from './carousal'
import AboutSection from './about-section'
import ServicesSection from './services-section'
import MissionVission from './mission-vision'
import EventSection from './event-section'
import Contact from './contact'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import DefaultProps from '../../../models/default_props'

class Home extends Component<DefaultProps, any> {
    render() {
        return (
            <div className="pt-5 mt-4 pt-md-0 mt-md-0">
                <CarouselPage />

                <AboutSection />
                
                <ServicesSection />

                <MissionVission />

                <EventSection history={this.props.history} location={this.props.location}/>

                <Contact />

                <ToastContainer />
            </div>
        )
    }
}

export default Home