/**
 * A helper to get promise based delay
 * @param ms - number of miliseconds to wait
 * @param data - data of type T to be sent back from promise after the delay
 * 
 * e.g.: await delay(3000)
 */
export const delayed = <T = any>(ms: number, data?: T) =>
	new Promise<T | undefined>(res => setTimeout(() => res(data), ms));

export const resolved = <T = any>(data: T) => new Promise<T>(res => res(data));

export const rejected = <T = any>(error?: any) => new Promise<T>((_, rej) => rej(error));
