import React, { Component } from 'react'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import RoutePath from '../../../../constants/route-paths';
import Theming from '../../../../constants/theming'
import * as auth from "../../../../firebase/auth";
import DefaultProps from '../../../../models/default_props';

interface ChangePasswordProps extends DefaultProps {
  passwordOne?: string;
  error?: any;
  passwordTwo?: string;
}

interface ChangePasswordState {
  passwordOne: string;
  error: any;
  passwordTwo: string;
}

class ChangePassword extends Component<ChangePasswordProps, ChangePasswordState> {
  private static INITIAL_STATE = {
    passwordOne: "",
    error: null,
    passwordTwo: ""
  };

  private static propKey(propertyName: string, value: any): object {
    return { [propertyName]: value };
  }

  constructor(props: ChangePasswordProps) {
    super(props);

    this.state = { ...ChangePassword.INITIAL_STATE };
  }

  public onSubmit = (event: any) => {
    const { passwordOne } = this.state;

    auth.doPasswordUpdate(passwordOne)
      .then(() => {
        this.props.history.push(RoutePath.home);
      })
      .catch((error: any) => {
        this.setState(ChangePassword.propKey("error", error));
      });

    event.preventDefault();
  };
  clearFields(this: any) {
    this.setState(ChangePassword.propKey("error", ''));
    this.setState(ChangePassword.propKey("passwordOne", ''));
    this.setState(ChangePassword.propKey("passwordTwo", ''));
  }

  showErrorMessage(message: any) {
    return (
      <div className="alert alert-warning alert-dismissible fade show" role="alert">
        {message}
        <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => this.clearFields()}>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    );
  }

  render() {
    const { passwordOne, passwordTwo, error } = this.state;
    const isInvalid = passwordOne !== passwordTwo || passwordOne === "";
    return (
      <>
        <div className="jumbotron d-flex justify-content-center py-5" style={{ backgroundColor: "white" }}>
          <div className="card" style={{ minWidth: "350px", maxWidth: "350px" }}>
            <h5 className="card-header text-uppercase text-center py-4" style={{ backgroundColor: Theming.backgroundColourLight, color: Theming.textColourLight }}>
              <strong>Change Password</strong>
            </h5>
            <div className="card-body px-lg-5 pt-0">
              <form className="text-center" onSubmit={event => this.onSubmit(event)}>
                <div className="md-form">
                  <input type="password" id="materialChangePasswordOne" className="form-control" style={{ color: Theming.textColourDark }} value={passwordOne} onChange={event => this.setStateWithEvent(event, "passwordOne")} />
                  <label htmlFor="materialChangePasswordOne" style={{ color: Theming.textColourDark }}>New Password</label>
                </div>
                <div className="md-form">
                  <input type="password" id="materialChangePasswordTwo" className="form-control" style={{ color: Theming.textColourDark }} value={passwordTwo} onChange={event => this.setStateWithEvent(event, "passwordTwo")} />
                  <label htmlFor="materialChangePasswordTwo" style={{ color: Theming.textColourDark }}>Confirm New Password</label>
                </div>
                <div className="py-2"></div>
                <button className="btn btn-block my-4 waves-effect z-depth-0" type="submit" disabled={isInvalid} style={{ borderColor: Theming.backgroundColourDark }}>Submit</button>
                {error && this.showErrorMessage(error.message)}
              </form>
            </div>
          </div>
        </div>
        <ToastContainer />
        <br /><br />
      </>
    )
  }
  private setStateWithEvent(event: any, columnType: string): void {
    this.setState(
      ChangePassword.propKey(columnType, (event.target as any).value)
    );
  }
}

export default ChangePassword