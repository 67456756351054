import React from 'react';
import Theming from '../../../constants/theming';
import DefaultProps from '../../../models/default_props';
import PromiseLoader from '../../templates/PromiseLoader';
import GalleryService from '../../../services/gallery_service';
import DisplayImages from '../Gallery/displayImages';

class Gallery extends PromiseLoader<any, DefaultProps> {
    async promise() {
        return GalleryService.getGallery()
    }
    render(){
        return(
            <main className="my-5 py-5">
                <div className="container" style={{ color: Theming.textColourDark }}>
                    <h2 className="h2-responsive"><span style={{ color: Theming.backgroundColourDark }}>SHAM</span> Gallery</h2>
                    <hr className="my-3"></hr>
                    {super.render()}
                </div>
            </main>
        )
    }

    dataComponent(galleryJson: any) {
        return (
            <section className="text-center">
                <div>
                    <DisplayImages images={galleryJson.Gallery} />
                </div>
            </section>
        );
    }
}

export default Gallery;