const RoutePath = {
	home: '/',
	teammembers: '/teammembers',
	journal: 'https://journal.sham.org.in',
	digital: '/digital',
	events: '/events',
	research: '/research',
	login: '/login',
	signup: '/signup',
	dashboard: '/dashboard',
	forgotPassword: '/forgot-password',
	gallery: '/gallery',
	membership: '/membership',
	newsletters: '/newsletters'
};

export default RoutePath;