import React from 'react';
import ReactMarkdown from 'react-markdown';
import { getLargeUrl } from '../../../helpers/image_helpers';
import MediaHelpers from '../../../helpers/media_file_helpers';
import DefaultProps from '../../../models/default_props';
import { NewsletterModel } from '../../../models/newsletter';
import NewsletterService from '../../../services/newsletter_service';
import PdfDisplayModal from '../../templates/PdfDisplayModal';
import PlaceholderImg from '../../templates/PlaceholderImg';
import PromiseLoader from '../../templates/PromiseLoader';
import Theming from '../../../constants/theming';
import { StrapiFile } from '../../../models/file';

class NewsletterDetails extends PromiseLoader<NewsletterModel, DefaultProps> {
    state = { toggleModalShow: false };

    async promise() {
        const id = this.props.location.pathname.split('/')[2];
        return NewsletterService.getNewsletterById(id);
    };

    toggleModal = (toggle?: boolean) => {
        if (toggle)
            this.setState({ toggleModalShow: toggle });
        this.setState({ toggleModalShow: !this.state.toggleModalShow });
    };

    renderActions = (document: StrapiFile) => {
        if (document) {
            return (
                <div key={document.id}>
                    <PdfDisplayModal toggleModal={this.toggleModal} modalShow={this.state.toggleModalShow} docUrl={document.url} />
                    <button onClick={(_) => this.toggleModal()} className="btn btn-md" style={{ backgroundColor: Theming.backgroundColourDark, color: Theming.textColourDark }}>Show Document</button>
                </div>
            );
        }
    }
        

    dataComponent(newsletterJson: NewsletterModel) {
        return (
            <main className="my-5 py-5">
                <div className="container">

                    <section className="mt-4">

                        <div className="row">

                            <div className="col">

                                <div className="card mb-4 wow fadeIn">
                                    <PlaceholderImg
                                        style={{ maxHeight: "70vh" }} className="img-fluid"
                                        blurhash={newsletterJson.thumbnail.formats.blurhash}
                                        src={MediaHelpers.resolveMediaUrl(getLargeUrl(newsletterJson.thumbnail))} alt={newsletterJson.thumbnail.alternativeText} />
                                </div>

                                <div className="card mb-4 wow fadeIn">

                                    <div className="card-header font-weight-bold">
                                        <p className="h5 my-3">{newsletterJson.title}</p>
                                    </div>

                                    <div className="card-body mx-2">
                                        <footer className="blockquote-footer pb-2">
                                            <cite title="Source Title">{new Date(newsletterJson.date).toLocaleDateString("en-IN")}</cite>
                                        </footer>

                                        <ReactMarkdown>{newsletterJson.description}</ReactMarkdown>

                                    </div>
                                </div>

                                <div className="card card-comments mb-3 wow fadeIn">
                                    <div className="card-header font-weight-bold"><span className="px-2">Actions</span></div>
                                    <div className="card-body">
                                        {this.renderActions(newsletterJson.Document)}
                                    </div>
                                </div>

                            </div>

                        </div>
                    </section>
                </div>
            </main>
        );
    }
}

export default NewsletterDetails;