import { CarouselModel } from '../models/carousel';
import CoreService from './core_service';
import RuntimeCache from './runtime_cache_service';

class CarouselService extends CoreService {
	static _instance: CarouselService;
	static getInstance = () => {
		if (!CarouselService._instance) {
			return CarouselService._instance = new CarouselService();
		}
		return CarouselService._instance;
	};

	cacheService: RuntimeCache;

	constructor() {
		super();
		this.cacheService = new RuntimeCache('carousel', false);
	}

	homeCarousel = () => this.cacheService.get<CarouselModel>('home-carousel') || { images: [], id: '' };

	getHomeCarousel = async () => {
		return this.cacheService.getElseSet<CarouselModel>(
			'home-carousel',
			async () => {
				const res = await this._strapi.request('GET', '/carousel') as CarouselModel;

				return res;
			}
		);
	};
}

export default CarouselService.getInstance();
