import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import RoutePath from '../../constants/route-paths'
import Theming from '../../constants/theming'

class MaintainancePage extends Component {
    render() {
        return (
            <div className="py-5" style={{ backgroundColor: Theming.backgroundColourLight}}>
                <div className="text-center py-5 px-4" style={{ color: Theming.textColourDark}}>
                    <div className="py-5">
                        <h1 className="card-title h1-responsive pt-3 font-bold py-5"><strong>PAGE UNDER MAINTAINANCE</strong></h1>
                        <Link className="btn btn-md py-3" to={RoutePath.home} style={{ color: Theming.textColourDark, borderColor: Theming.textColourDark}}><i className="fas fa-home"></i> Return Home</Link>
                    </div>
                </div>
            </div>
        )
    }
}

export default MaintainancePage