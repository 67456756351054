import React from 'react';
import ReactMarkdown from 'react-markdown';
import MediaHelpers from '../../../helpers/media_file_helpers';
import DefaultProps from '../../../models/default_props';
import { DigitalArchieveModel } from '../../../models/digital';
import ArchiveService from '../../../services/digital_archive_service';
import PromiseLoader from '../../templates/PromiseLoader';
import Theming from '../../../constants/theming';
import PdfDisplayModal from '../../templates/PdfDisplayModal';
import { StrapiFile } from '../../../models/file';
import RoutePath from '../../../constants/route-paths';
import { Link } from 'react-router-dom';
import { getLargeUrl } from '../../../helpers/image_helpers';
import PlaceholderImg from '../../templates/PlaceholderImg';


class ArchiveDetails extends PromiseLoader<DigitalArchieveModel, DefaultProps> {
    state = { togglePreviewModalShow: false, toggleFullModalShow: false };

    async promise() {
        const id = this.props.location.pathname.split('/')[2];
        return ArchiveService.getArchiveById(id);
    };

    togglePreviewModal = (toggle?: boolean) => {
        if (toggle)
            this.setState({ togglePreviewModalShow: toggle });
        this.setState({ togglePreviewModalShow: !this.state.togglePreviewModalShow });
    };

    toggleFullModal = (toggle?: boolean) => {
        if (toggle)
            this.setState({ toggleFullModalShow: toggle });
        this.setState({ toggleFullModalShow: !this.state.toggleFullModalShow });
    };

    renderActions = (file: {
        full: StrapiFile;
        preview: StrapiFile;
    }) => {
        const actions: JSX.Element[] = [];

        if (file) {
            if (file.preview) {
                actions.push(
                    <div key={file.preview.id}>
                        <PdfDisplayModal toggleModal={this.togglePreviewModal} modalShow={this.state.togglePreviewModalShow} docUrl={file.preview.url} />
                        <button onClick={(_) => this.togglePreviewModal()} className="btn btn-md" style={{ backgroundColor: Theming.backgroundColourDark, color: Theming.textColourDark }}>Show Preview</button>
                    </div>
                );

                if (file.full)
                    actions.push(
                        <div key={file.full.id}>
                            <PdfDisplayModal toggleModal={this.toggleFullModal} modalShow={this.state.toggleFullModalShow} docUrl={file.full.url} />
                            <button onClick={(_) => this.toggleFullModal()} className="btn btn-md" style={{ backgroundColor: Theming.backgroundColourDark, color: Theming.textColourDark }}>Show Full Document</button>
                            <a href={file.full.url} download target="_blank" rel="noopener noreferrer" className="btn btn-md" style={{ backgroundColor: Theming.backgroundColourDark, color: Theming.textColourDark }}>Download</a>
                        </div>
                    );
                else
                    actions.push(
                        <div key={'buy membership message'} className="pt-2 pl-2">
                            You need to <Link to={RoutePath.membership}>buy membership</Link> to view or download the full document.
                        </div>
                    );
            }
        }

        const login_msg = (
            <div key={'login message'} className="pt-2 pl-2">
                You must <Link to={RoutePath.login}>login</Link> to see the preview
            </div>
        );

        return (
            <div>
                {actions.length === 0 ? login_msg : actions}
            </div>
        );
    };

    dataComponent(archive: DigitalArchieveModel) {
        return (
            <main className="my-5 py-5">
                <div className="container">

                    <section className="mt-4">

                        <div className="row">

                            <div className="col">

                                <div className="card mb-4 wow fadeIn">
                                    <PlaceholderImg
                                        blurhash={archive.thumbnail.formats.blurhash}
                                        style={{ maxHeight: "70vh", objectFit: "cover" }}
                                        className="card-img d-block w-100"
                                        src={MediaHelpers.resolveMediaUrl(getLargeUrl(archive.thumbnail))} alt={archive.thumbnail.alternativeText} />
                                </div>

                                <div className="card mb-4 wow fadeIn">

                                    <div className="card-header font-weight-bold">
                                        <p className="h5 my-3">{archive.title}</p>
                                    </div>

                                    <div className="card-body mx-2">
                                        <footer className="blockquote-footer pb-2">
                                            <cite title="Source Title">{new Date(archive.published_at).toLocaleDateString("en-IN")}</cite>
                                        </footer>

                                        <ReactMarkdown>{archive.description}</ReactMarkdown>

                                    </div>
                                </div>

                                <div className="card card-comments mb-3 wow fadeIn">
                                    <div className="card-header font-weight-bold"><span className="px-2">Actions</span></div>
                                    <div className="card-body">
                                        {this.renderActions(archive.file)}
                                    </div>
                                </div>

                            </div>

                        </div>
                    </section>
                </div>
            </main>
        );
    }
}

export default ArchiveDetails;