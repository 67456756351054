import { firestore } from "./firebase";

export const doCreateUser = (id: string, username: string, email: string) =>
	firestore.collection('users').doc(id).set({
		email,
		username
	});

export const doUpdateUser = (id: string, profile?: {
	displayName?: string | null | undefined;
	photoURL?: string | null | undefined;
}) => profile ?
		firestore.collection('users').doc(id).update(profile) :
		new Promise<void>((res) => res());

export const getUser = (id: string) =>
	firestore.collection('users').doc(id).get().then(val => val.data());