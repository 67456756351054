import React, { Component } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import Theming from '../../../../constants/theming';
import * as auth from '../../../../firebase/auth';
import AuthService from '../../../../services/auth_service';
import { AppContextSchema, withAppContext } from '../../../app-context';

interface ProfileProps extends AppContextSchema { }

class Profile extends Component<ProfileProps> {
    sendVerificationMail() {
        auth.doSendVerificationMail();
    }

    isEmailVerified() {
        return (this.props.currentUser?.emailVerified ?
            (<h5><i className="fas fa-check" style={{ color: "green" }}></i> Email is Verified</h5>)
            :
            (<>
                <h5><i className="fas fa-times" style={{ color: "red" }}></i> Email is Not Verified
                    <button className="btn my-4 z-depth-0" type="submit" onClick={this.sendVerificationMail} style={{ borderColor: Theming.backgroundColourDark }}>Verify</button>
                </h5>
            </>)
        );
    }

    checkMembership() {
        const role = AuthService.getUserRole()?.type;

        switch (role) {
            case 'member':
                return (
                    <h5>
                        <i className="fas fa-check" style={{ color: "green" }}></i>
                        <span style={{ color: Theming.backgroundColourDark, fontWeight: "bold" }}> SHAM</span> Member
                    </h5>
                );
            default:
                return (
                    <h5>
                        <i className="fas fa-times" style={{ color: "red" }}></i>
                        <> Not a </><span style={{ color: Theming.backgroundColourDark, fontWeight: "bold" }}>SHAM</span> Member
                    </h5>
                );
        }
    }

    render() {
        if (!this.props.currentUser)
            return <div></div>;
        return (
            <>
                <div className="jumbotron d-flex justify-content-center py-5 my-5" style={{ backgroundColor: "white" }}>
                    <div className="card" style={{ minWidth: "350px", maxWidth: "500px" }}>
                        <h5 className="card-header text-uppercase text-center py-4" style={{ backgroundColor: Theming.backgroundColourLight, color: Theming.textColourLight }}>
                            <strong>My Profile</strong>
                        </h5>
                        <div className="card-body px-lg-5 pt-0">
                            <form style={{ color: Theming.textColourDark }}>
                                <div className="md-form">
                                    <h5><i className="far fa-user"></i> {this.props.currentUser.displayName}</h5>
                                </div>
                                <div className="md-form">
                                    <h5><i className="far fa-envelope"></i> {this.props.currentUser.email}</h5>
                                </div>
                                <div className="md-form">
                                    {this.isEmailVerified()}
                                </div>
                                <div className="md-form">
                                    {this.checkMembership()}
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <br /><br />
            </>
        );
    }
}

export default withAppContext(Profile);
