import React from 'react';
import { Switch, Route } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Header from './layouts/Header';
import Footer from './layouts/Footer';
import Home from './route-layouts/home/home';
import Events from './route-layouts/Events/events';
import Login from './route-layouts/SignIn';
import Signup from './route-layouts/SignUp';
//import ResearchPapers from './route-layouts/research-paper';
import NotFoundPage from './route-layouts/not-found-page';
import MaintainancePage from './route-layouts/maintainance';
import RoutePath from '../constants/route-paths';
import * as firebase from "../firebase/firebase";
import ForgotPassword from './route-layouts/ForgotPassword/ForgotPassword';
import Dashboard from './route-layouts/Dashboard/dashboard';
import EventDetails from './route-layouts/Events/event_details';
import PromiseLoader from './templates/PromiseLoader';
import { Unsubscribe, User } from 'firebase';
import AppContext from './app-context';
import TeamMembers from './route-layouts/TeamMembers/teammembers';
import TeamMembersDetails from './route-layouts/TeamMembers/teammembersdetails';
import Gallery from './route-layouts/Gallery';
import Membership from './route-layouts/Membership';
import * as auth from "../firebase/auth";
import CarouselService from '../services/carousel_service';
import { delayed } from '../helpers/promise_helpers';
import DigitalArchive from './route-layouts/digital/archives';
import ArchiveDetails from './route-layouts/digital/archive_details';
import Newsletters from './route-layouts/Newsletters/Newsletters';
import NewsletterDetails from './route-layouts/Newsletters/newsletter_details';

class AppComponent extends PromiseLoader<(User | null)> {
  // Unsubscription handler to stop listening to user update when not required
  unsubscribeAuthStateChange!: Unsubscribe;

  promise() {
    const carouselLoad = CarouselService.getHomeCarousel();

    return new Promise<User | null>((resolve, reject) => {
      const delay = delayed(3000);
      this.unsubscribeAuthStateChange = firebase.auth.onAuthStateChanged(
        async user => {
          if (user)
            await auth.doBackendAuth(user);
          await carouselLoad;
          await delay;
          resolve(user);
        },
        error => reject(error),
      );
    });
  }

  loadingComponent() {
    // TODO: Add loading component on splash
    return (
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
        <img className="animate-img" src="/img/logo.png" alt="logo" />
      </div>
    );
  }

  errorComponent(_error: any) {
    // Promise is complete with error, stop onChange listner
    this.unsubscribeAuthStateChange();
    // TODO: Add error component after splash
    return (
      <div style={{ position: "fixed", top: "50%", left: "40%" }}>
        <h1 className="h1-responsive">Server Error</h1>
      </div>
    );
  }

  dataComponent(currentUser: User | null) {
    // Promise is complete with data in currentUser, so stop onChange listner
    this.unsubscribeAuthStateChange();
    const appContext = {
      currentUser,
      updateUser: (_user: User | null) => this.setPromise(),
      isAuthenticated: () => currentUser
    };

    return (
      <AppContext.Provider value={appContext}>
        <div id="page-container">
          <div id="content-wrap">
            <Header />
            <Switch>
              <Route exact path={RoutePath.home} component={Home} />
              <Route exact path={RoutePath.teammembers} component={TeamMembers} />
              <Route exact path={`${RoutePath.teammembers}/:memberId`} component={TeamMembersDetails} />
              <Route exact path={`${RoutePath.digital}/:id`} component={ArchiveDetails} />
              <Route path={RoutePath.digital} component={DigitalArchive} />
              <Route exact path={`${RoutePath.events}/:id`} component={EventDetails} />
              <Route path={RoutePath.events} component={Events} />
              <Route path={RoutePath.login} component={Login} />
              <Route path={RoutePath.signup} component={Signup} />
              <Route path={RoutePath.dashboard} component={Dashboard} />
              <Route path={RoutePath.forgotPassword} component={ForgotPassword} />
              <Route path={RoutePath.research} component={MaintainancePage} />
              <Route path={RoutePath.gallery} component={Gallery} />
              <Route path={RoutePath.membership} component={Membership} />
              <Route exact path={`${RoutePath.newsletters}/:id`} component={NewsletterDetails} />
              <Route path={RoutePath.newsletters} component={Newsletters} />
              <Route component={NotFoundPage} />
            </Switch>
          </div>
          <div id="footer">
            <Footer />
          </div>
        </div>
      </AppContext.Provider>
    );
  }
}

export default AppComponent;