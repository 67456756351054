import { DigitalArchieveModel, DigitalArchieveResponse } from '../models/digital';
import { QueryParams } from '../models/params_model';
import CoreService from './core_service';
import RuntimeCache from './runtime_cache_service';

class DigitalArchiveService extends CoreService {
	static _instance: DigitalArchiveService;
	static getInstance = () => {
		if (!DigitalArchiveService._instance) {
			return DigitalArchiveService._instance = new DigitalArchiveService();
		}
		return DigitalArchiveService._instance;
	};

	cacheService: RuntimeCache;

	constructor() {
		super();
		this.cacheService = new RuntimeCache('digital-archive');
	}

	getArchives = async (page: number, _limit: number, find?: string) => {
		return this.cacheService.getElseSet<DigitalArchieveResponse>(
			page.toString() + _limit.toString() + find || '',
			async () => {
				const _start = _limit * (page - 1);
				const params: QueryParams = { _start, _limit, _sort: 'published_at:desc' };
				if (find)
					params._q = find;

				const res = await this._strapi.getEntriesWithCount<DigitalArchieveModel>('digital-archives', {
					params, headers: this._withAuthHeader()
				});
				const totalPages = res.totalCount ? Math.ceil(res.totalCount / _limit) : 1;

				res.data.forEach(e => {
					this.cacheService.set(e.id, e);
				});

				return {
					page,
					totalPages,
					pageData: res.data
				};
			}
		);
	};

	getArchiveById = (id: string) => this.cacheService.getElseSet<DigitalArchieveModel>(id, () => {
		return this._strapi.request(
			'GET',
			`digital-archives/${id}`,
			{ headers: this._withAuthHeader() }
		);
	});
}

export default DigitalArchiveService.getInstance();
