import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import ChangePassword from './ChangePassword/ChangePassword';
import RoutePath from '../../../constants/route-paths';
import './dashboard.css';
import Logout from './SignOut/logout';
import Profile from './Profile/profile';
import { AppContextSchema, withAppContext } from '../../app-context';
import DefaultProps from '../../../models/default_props';
import {Tab, Row, Col, Nav} from 'react-bootstrap';
import Theming from '../../../constants/theming';

interface DashboardProps extends DefaultProps, AppContextSchema { }

interface DashboardState {
    addClassInProfile: boolean,
    addClassInChangePass: boolean,
    addClassInLogout: boolean,
    redirect?: string;
}

class Dashboard extends Component<DashboardProps, DashboardState> {
    constructor(props: DashboardProps) {
        super(props);
        this.state = { addClassInProfile: true, addClassInChangePass: false, addClassInLogout: false };
        this.toggleProfile = this.toggleProfile.bind(this);
        this.toggleChangePass = this.toggleChangePass.bind(this);
        this.toggleLogout = this.toggleLogout.bind(this);
    }

    toggleProfile() {
        this.setState({
            addClassInProfile: !this.state.addClassInProfile,
            addClassInChangePass: false,
            addClassInLogout: false
        });
    }

    toggleChangePass() {
        this.setState({
            addClassInChangePass: !this.state.addClassInChangePass,
            addClassInProfile: false,
            addClassInLogout: false
        });
    }

    toggleLogout() {
        this.setState({
            addClassInLogout: !this.state.addClassInLogout,
            addClassInChangePass: false,
            addClassInProfile: false
        });
    }

    componentDidUpdate() {
        if (!this.props.currentUser)
            this.setState({ redirect: RoutePath.home });
    }

    render() {
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} />;
        }
        return (
            <div className="container-fluid" style={{ marginTop: "120px" }}>
                <Tab.Container id="left-tabs-example" defaultActiveKey="first" >
                    <Row>
                        <Col sm={3}>
                        <Nav variant="pills" className="flex-column">
                            <Nav.Item>
                            <Nav.Link eventKey="first" style={{ color: Theming.textColourDark }} className="text-uppercase"><h6>My Profile</h6></Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                            <Nav.Link eventKey="second" style={{ color: Theming.textColourDark }} className="text-uppercase"><h6>Change Password</h6></Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                            <Nav.Link eventKey="third" style={{ color: Theming.textColourDark }} className="text-uppercase"><h6>Logout</h6></Nav.Link>
                            </Nav.Item>
                        </Nav>
                        </Col>
                        <Col sm={9}>
                        <Tab.Content>
                            <Tab.Pane eventKey="first">
                                <Profile />
                            </Tab.Pane>
                            <Tab.Pane eventKey="second">
                                <ChangePassword history={this.props.history} location={this.props.location} />
                            </Tab.Pane>
                            <Tab.Pane eventKey="third">
                                <Logout history={this.props.history} location={this.props.location} />
                            </Tab.Pane>
                        </Tab.Content>
                        </Col>
                    </Row>
                </Tab.Container>
            </div>
        );
    }
}

export default withAppContext(Dashboard);
