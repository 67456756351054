import React, { Component } from 'react';
import { BlurhashCanvas } from 'react-blurhash';

interface PlaceholderImgProps {
    blurhash?: string;
    style?: React.CSSProperties;
    alt?: string;
    src?: string;
    className?: string;
}

interface PlaceholderImgState {
    isLoaded: boolean;
}

export default class PlaceholderImg extends Component<PlaceholderImgProps, PlaceholderImgState> {
    state = { isLoaded: false };

    render() {
        const style: React.CSSProperties = { ...this.props.style };

        if (!this.state.isLoaded)
            style["height"] = "0px";

        return (
            <div>
                {!this.state.isLoaded && this.props.blurhash != null && <BlurhashCanvas
                    hash={this.props.blurhash}
                    className={this.props.className}
                    style={this.props.style}
                />}
                <img
                    className={this.props.className}
                    src={this.props.src}
                    alt={this.props.alt}
                    style={style}
                    onLoad={() => this.setState({ isLoaded: true })}
                />
            </div>
        );
    }
}