import React from 'react';
import MediaHelpers from '../../../helpers/media_file_helpers';
import { Image } from '../../../models/image';

export default function ProfileImage(props: { picture: Image; objectFit?: "cover" | "contain"; }) {
    return (
        <img
            src={props.picture ? MediaHelpers.resolveMediaUrl(props.picture.url) : "https://dummyimage.com/260x344"}
            className="card-img-top mb-2" alt="ProfileImage" style={{ objectFit: props.objectFit || "contain", height: "44vh" }} />
    );
}