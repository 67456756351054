export default class AsyncSnapshot<T = any> {
	/**
	 * @member status
	 * holds the status of the Promise. When status is
	 * "loading", the promise is not yet complete and
	 * there's no data in `error` or `data`
	 */
	status: "loading" | "complete" | "failed";

	/**
	 * holds the error when the status is "failed"
	 */
	error?: any;

	/**
	 * holds the data when status is "complete"
	 */
	data?: T;

	/**
	 * 
	 * @param status - represent the status of the Promise
	 * @param error - represents the error when the status is "failed"
	 * @param data - represents the data when status is "complete"
	 */
	constructor(status: "loading" | "complete" | "failed", error: any, data: T | undefined) {
		this.status = status;
		this.error = error;
		this.data = data;
	}
}