import React from 'react';
import DefaultProps from '../../../models/default_props';
import { TeamMember } from '../../../models/team_member';
import TeamMemberService from '../../../services/team_member_service';
import PromiseLoader from '../../templates/PromiseLoader';
import ProfileImage from './ProfileImage';

class TeamMembersDetails extends PromiseLoader<TeamMember, DefaultProps> {
    promise() {
        const id = this.props.location.pathname.split('/')[2];
        return TeamMemberService.getMemberById(id);
    }

    dataComponent(data: TeamMember) {
        return (
            <div className="container item-align-center py-5 my-5" id="page-content">
                <div className="py-5">
                    <div className="row container d-flex justify-content-center">
                        <div className="col-md-10">
                            <div className="card">
                                <div className="card-body text-center">
                                    <div>
                                        <ProfileImage picture={data.picture} objectFit="contain" />
                                        <h2>{data.name}</h2>
                                        <h4 className="text-muted mb-0">{data.sham_designation}</h4>
                                        <h5 className="text-muted mb-0">{data.designation}</h5>
                                    </div>
                                    <hr className="my-3"></hr>
                                    <p className="mt-2 card-text"><span style={{ fontWeight: "bold" }}>About: </span>{data.about}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default TeamMembersDetails;