import React from 'react';
import { Link } from 'react-router-dom';
import RoutePath from '../../../constants/route-paths';
import Theming from '../../../constants/theming';
import { getSmallUrl } from '../../../helpers/image_helpers';
import MediaHelpers from '../../../helpers/media_file_helpers';
import { genRoutePath } from '../../../helpers/route_helpers';
import { DigitalArchieveModel } from '../../../models/digital';
import PlaceholderImg from '../../templates/PlaceholderImg';

const routeToArchive = (ArchiveId: string) => genRoutePath([RoutePath.digital, ArchiveId]);

interface ArchiveCardProps {
    archive: DigitalArchieveModel;
}

export default class ArchiveCard extends React.Component<ArchiveCardProps> {
    render() {
        return (
            <div className="col-lg-4 col-md-6 mb-4">

                <div className="card">

                    <div className="view overlay">
                        <PlaceholderImg
                            blurhash={this.props.archive.thumbnail.formats.blurhash}
                            style={{ height: "30vh", objectFit: "cover" }} className="card-img-top"
                            src={MediaHelpers.resolveMediaUrl(getSmallUrl(this.props.archive.thumbnail))} alt={this.props.archive.thumbnail.alternativeText} />
                        <Link to={routeToArchive(this.props.archive.id)}>
                            <div className="mask rgba-white-slight"></div>
                        </Link>
                    </div>

                    <div className="card-body text-left">
                        <p>
                            <strong>
                                <i className="far fa-clock"></i> {new Date(this.props.archive.published_at).toLocaleDateString("en-IN")}
                            </strong>
                        </p>

                        <h4 className="card-title">{this.props.archive.title}</h4>

                        <Link to={routeToArchive(this.props.archive.id)} className="btn btn-md" style={{ backgroundColor: Theming.backgroundColourDark, color: Theming.textColourDark }}>
                            More Details
                            <i className="fas fa-play ml-2"></i>
                        </Link>
                    </div>

                </div>
            </div >
        );
    }
}