import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import RoutePath from '../../../constants/route-paths'
import Theming from '../../../constants/theming'
import * as auth from "../../../firebase/auth";
import DefaultProps from '../../../models/default_props';
import { AppContextSchema, withAppContext } from '../../app-context';

interface InterfaceProps extends DefaultProps, AppContextSchema {
  email?: string;
  error?: any;
  password?: string;
}

interface InterfaceState {
  email: string;
  error: any;
  password: string;
}

class LoginForm extends Component<InterfaceProps, InterfaceState> {
  private static INITIAL_STATE = {
    email: "",
    error: null,
    password: ""
  };

  private static propKey(propertyName: string, value: any): object {
    return { [propertyName]: value };
  }

  constructor(props: InterfaceProps) {
    super(props);

    this.state = { ...LoginForm.INITIAL_STATE };
  }

  private onSignInWithEmail = (event: React.FormEvent) => {
    const { email, password } = this.state;
    const { history } = this.props;

    auth.doSignInWithEmailAndPassword(email, password)
      .then((userCred) => {
        this.props.updateUser(userCred.user);
        history.push(RoutePath.home);
      })
      .catch((error: any) => {
        this.setState(LoginForm.propKey("error", error));
      });

    event.preventDefault();
  };

  private onSignInWithFb = (event: React.FormEvent) => {
    const { history } = this.props;

    auth.doSignInUsingFb()
      .then((userCred) => {
        this.props.updateUser(userCred?.user || null);
        history.push(RoutePath.home);
      })
      .catch((error: any) => {
        this.setState(LoginForm.propKey("error", error));
      });

    event.preventDefault();
  };

  private onSignInWithGoogle = (event: React.FormEvent) => {
    const { history } = this.props;

    auth.doSignInUsingGoogle()
      .then((userCred) => {
        this.props.updateUser(userCred?.user || null);
        history.push(RoutePath.home);
      })
      .catch((error: any) => {
        this.setState(LoginForm.propKey("error", error));
      });

    event.preventDefault();
  };

  clearFields() {
    this.setState(() => ({ ...LoginForm.INITIAL_STATE }));
  }

  showErrorMessage(message: any) {
    return (
      <div className="alert alert-warning alert-dismissible fade show" role="alert">
        {message}
        <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => this.clearFields()}>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    );
  }

  render() {
    const { email, password, error } = this.state;
    const isInvalid = password === "" || email === "";
    return (
      <div className="d-flex justify-content-center py-5 my-5" >
        <div className="card" style={{ minWidth: "400px", maxWidth: "500px" }}>
          <h5 className="card-header text-uppercase text-center py-4" style={{ backgroundColor: Theming.backgroundColourLight, color: Theming.textColourLight }}>
            <strong>Sign in</strong>
          </h5>
          <div className="card-body px-lg-5 pt-0">
            <form className="text-center" onSubmit={this.onSignInWithEmail}>
              <div className="md-form">
                <input type="email" id="materialLoginFormEmail" className="form-control" style={{ color: Theming.textColourDark }} value={email} onChange={event => this.setStateWithEvent(event, "email")} />
                <label htmlFor="materialLoginFormEmail" style={{ color: Theming.textColourDark }}>E-mail</label>
              </div>
              <div className="md-form">
                <input type="password" id="materialLoginFormPassword" className="form-control" style={{ color: Theming.textColourDark }} value={password} onChange={event => this.setStateWithEvent(event, "password")} />
                <label htmlFor="materialLoginFormPassword" style={{ color: Theming.textColourDark }}>Password</label>
              </div>
              <div className="d-flex justify-content-around">
                <div>
                  <Link to={RoutePath.forgotPassword} style={{ textDecoration: "none", color: Theming.textColourDark }}> Forgot password?</Link>
                </div>
              </div>
              <button className="btn btn-block my-4 waves-effect z-depth-0" type="submit" disabled={isInvalid} style={{ borderColor: Theming.backgroundColourDark }}>Sign in</button>
              <p style={{ color: Theming.textColourDark }} >Not a member?
                                <Link to={RoutePath.signup} style={{ textDecoration: "none", color: Theming.textColourDark }}> Register</Link>
              </p>
              <p style={{ color: Theming.textColourDark }}>or sign in with:</p>
              <button type="button" className="btn btn-md" onClick={this.onSignInWithFb} style={{ color: "white", backgroundColor: "#3b5998" }}><i className="fab fa-facebook-f pr-1"></i> Facebook</button>
              <button type="button" className="btn btn-md" onClick={this.onSignInWithGoogle} style={{ color: "white", backgroundColor: "#dd4b39" }}><i className="fab fa-google"></i> Google</button>
              {error && this.showErrorMessage(error.message)}
              <ToastContainer />
            </form>
          </div>
        </div>
      </div>
    )
  }
  private setStateWithEvent(event: any, columnType: string): void {
    this.setState(LoginForm.propKey(columnType, (event.target as any).value));
  }
}

export default withAppContext(LoginForm);