import { EventModel, EventsResponse } from '../models/event';
import { QueryParams } from '../models/params_model';
import CoreService from './core_service';
import RuntimeCache from './runtime_cache_service';

class EventService extends CoreService {
	static _instance: EventService;
	static getInstance = () => {
		if (!EventService._instance) {
			return EventService._instance = new EventService();
		}
		return EventService._instance;
	};

	cacheService: RuntimeCache;

	constructor() {
		super();
		this.cacheService = new RuntimeCache('events', false);
	}

	getEvents = async (page: number, _limit: number, find?: string) => {
		return this.cacheService.getElseSet<EventsResponse>(
			page.toString() + _limit.toString() + find || '',
			async () => {
				const _start = _limit * (page - 1);
				const params: QueryParams = { _start, _limit, _sort: 'published_at:desc' };
				if(find)
					params._q = find;

				const eventsRes = await this._strapi.getEntriesWithCount<EventModel>('events', {
					params
				});
				const totalPages = eventsRes.totalCount ? Math.ceil(eventsRes.totalCount / _limit) : 1;

				eventsRes.data.forEach(e => {
					this.cacheService.set(e.id, e);
				});

				return {
					page,
					totalPages,
					pageData: eventsRes.data
				};
			}
		);
	};

	getEventById = (id: string) => this.cacheService.getElseSet<EventModel>(id, () => {
		return this._strapi.getEntry('events', id) as Promise<EventModel>;
	});
}

export default EventService.getInstance();
