import React, { Component } from 'react';
import Theming from '../../constants/theming';

interface LoadingButtonProps {
    onClick: () => Promise<void>,
    disabled?: boolean;
}

interface LoadingButtonState {
    loading: boolean;
}

export default class LoadingButton extends Component<LoadingButtonProps, LoadingButtonState> {
    constructor(props: LoadingButtonProps) {
        super(props);
        this.state = { loading: false };
    }

    handleClick = async () => {
        this.setState({ loading: true });
        try {
            await this.props.onClick();
        }
        finally {
            this.setState({ loading: false });
        }
    };

    render() {
        if (this.state.loading)
            return (
                <button className="btn" type="button" style={{ color: Theming.textColourDark, borderColor: Theming.textColourDark, backgroundColor: Theming.backgroundColourDark }} disabled>
                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    <span style={{ color: Theming.textColourDark, borderColor: Theming.textColourDark, backgroundColor: Theming.backgroundColourDark }}>  Loading...</span>
                </button>
            );
        else
            return (
                <button onClick={this.handleClick} className="btn" style={{ color: Theming.textColourDark, borderColor: Theming.textColourDark, backgroundColor: Theming.backgroundColourDark }} disabled={this.props.disabled}>{this.props.children}</button>
            );
    }
}
