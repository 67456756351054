import * as React from "react";
import { withRouter } from "react-router-dom";
import { SignupForm } from "./signupForm";

const SignUpComponent = ({ history, location }: { [key: string]: any }) => (
    <SignupForm history={history} location={location} />
);

const Signup = withRouter(SignUpComponent);

export default Signup;