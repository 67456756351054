import { User } from 'firebase';
import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import RoutePath from '../../constants/route-paths'
import Theming from '../../constants/theming'
import { AppContextSchema, withAppContext } from '../app-context';
import './Header.css'

interface HeaderProps extends AppContextSchema { }

interface HeaderState {
    showNav: boolean
}

class Header extends Component<HeaderProps, HeaderState> {
    constructor(props: HeaderProps) {
        super(props)
        this.state = { showNav: false }
        this.toggleNav = this.toggleNav.bind(this)
    }

    toggleNav() {
        this.setState({
            showNav: !this.state.showNav
        })
    }

    renderAuthLink = (authUser: User | null) => {
        if (authUser)
            return <Link className="nav-link" style={{ color: Theming.textColourDark, fontWeight: "bold" }} to={RoutePath.dashboard}><i className="fa fa-user"></i> Dashboard</Link>
        else
            return <Link className="nav-link" style={{ color: Theming.textColourDark, fontWeight: "bold" }} to={RoutePath.login}>Login</Link>
    }

    render() {
        const { showNav } = this.state;

        return (
            <nav className="navbar fixed-top navbar-expand-lg scrolling-navbar">
                <Link className="navbar-brand" to={RoutePath.home}><img src="/img/logo.png" alt="logo" style={{ width: "150px" }} /></Link>
                <button className="navbar-toggler" type="button" onClick={this.toggleNav}>
                    <i className="fas fa-bars"></i>
                </button>
                <div className={(showNav ? 'show' : '') + "collapse navbar-collapse text-uppercase"} ref="mynav">
                    <ul className="navbar-nav mr-auto">
                    </ul>
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <a rel="noopener noreferrer" className="nav-link" style={{ color: Theming.textColourDark, fontWeight: "bold" }} target="_blank" href={RoutePath.journal}>Journal</a>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" style={{ color: Theming.textColourDark, fontWeight: "bold" }} to={RoutePath.digital}>Digital Archive</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" style={{ color: Theming.textColourDark, fontWeight: "bold" }} to={RoutePath.research}>Research Activity</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" style={{ color: Theming.textColourDark, fontWeight: "bold" }} to={RoutePath.gallery}>Gallery</Link>
                        </li>
                        <li className="nav-item">
                            {this.renderAuthLink(this.props.currentUser)}
                        </li>
                    </ul>
                </div>
            </nav>
        )
    }
}

export default withAppContext(Header);
