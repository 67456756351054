import React from "react";
import { Link } from 'react-router-dom';
import RoutePath from "../../constants/route-paths";
import Theming from '../../constants/theming';

const Footer = () => {
  return (
    <footer className="page-footer font-small pt-4" style={{ backgroundColor: Theming.backgroundColourDark }}>
        <div className="container text-center text-md-left" style={{ color: Theming.textColourDark }}>
            <div className="row background-colour-light-1">
                <div className="col-md-6 mt-md-0 mt-3">
                    <h5 className="text-uppercase">sham.org.in</h5>
                    <p>Some Content</p>
                </div>
                <hr className="clearfix w-100 d-md-none pb-3" />
                <div className="col-md-2 mb-md-0 mb-2 text-colour-dark-2">
                    <h5 className="text-uppercase">Society</h5>
                    <ul className="list-unstyled">
                        <li>
                            <Link to={RoutePath.gallery} style={{ color: Theming.textColourDark }}>Gallery</Link>
                        </li>
                        <li>
                            <Link to="/" style={{ color: Theming.textColourDark }}>Support</Link>
                        </li>
                        <li>
                            <Link to={RoutePath.teammembers} style={{ color: Theming.textColourDark }}>Team Members</Link>
                        </li>
                    </ul>
                </div>
                <div className="col-md-2 mb-md-0 mb-2">
                    <h5 className="text-uppercase">Features</h5>
                    <ul className="list-unstyled">
                        <li>
                            <Link to={RoutePath.digital} style={{ color: Theming.textColourDark }}>Digital Archive</Link>
                        </li>
                        <li>
                            <a rel="noopener noreferrer" style={{ color: Theming.textColourDark }} target="_blank" href={RoutePath.journal}>Journal</a>
                        </li>
                        <li>
                            <Link to={RoutePath.research} style={{ color: Theming.textColourDark }}>Research Activity</Link>
                        </li>
                        <li>
                            <Link to={RoutePath.events} style={{ color: Theming.textColourDark }}>Events</Link>
                        </li>
                    </ul>
                </div>
                <div className="col-md-2 mb-md-0 mb-2">
                    <h5 className="text-uppercase">Contact Us</h5>
                    <ul className="list-unstyled">
                        <li>
                            <i className="far fa-envelope"></i> info@sham.org.in
                        </li>
                        <li>
                            <i className="fas fa-phone"></i> +91 9432862579
                        </li>
                        <li>
                            <i className="far fa-address-card"></i> Kolkata, West Bengal
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div className="footer-copyright text-center py-3" style={{ color: Theming.textColourDark }}>
            <h6 style={{ display: "inline" }}>
                © 2020 Copyright:
                <Link to={RoutePath.home} style={{ color: Theming.textColourDark }}> Society for Heritage, Archeology &amp; Management</Link>
            </h6>
        </div>
    </footer>
  );
}

export default Footer;