import { Membership } from '../models/membership';
import CoreService from './core_service';

class MembershipService extends CoreService {
	static _instance: MembershipService;
	static getInstance = () => {
		if (!MembershipService._instance) {
			return MembershipService._instance = new MembershipService();
		}
		return MembershipService._instance;
	};

	getMembershipStatus = async () => {
		const MembershipRes = await this._strapi.request(
			'GET',
			'membership/requests', {
			headers: this._withAuthHeader()
		}
		);

		return MembershipRes as Membership[];
	};

	applyMembership = async (paymentID: String, email: String) => {
		const MembershipRes = await this._strapi.request(
			'POST',
			'membership/request', {
			headers: this._withAuthHeader(),
			data: { paymentID, email }
		}
		);

		return MembershipRes as Membership;
	};

	cancelMembership = async (id: String) => {
		const MembershipRes = await this._strapi.request(
			'DELETE',
			`membership/requests/${id}`, {
			headers: this._withAuthHeader()
		}
		);

		return MembershipRes as Membership;
	};
}

export default MembershipService.getInstance();