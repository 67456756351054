import React from "react";
import GalleryBuilder from '../Gallery/gallery_builder';
import { Image } from '../../../models/image';

const DisplayImages = (props: { images: Image[]; }) => {
    return (
        <GalleryBuilder images={props.images} />
    );
};

export default DisplayImages;