import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import RoutePath from '../../../constants/route-paths'
import Theming from '../../../constants/theming'
import { findMemberByDesignation } from '../../../helpers/teamMembersList'

const presidentData = findMemberByDesignation('President')[0]

class AboutSection extends Component {
    render() {
        return (
            <div className="container-fluid" style={{ backgroundColor: Theming.backgroundColourLight }}>
                <section className="container dark-grey-text">
                    <div className="row pr-lg-5">
                        <div className="col-md-5 my-5">
                            <div className="viewr text-center align-items-center">
                                <img className="img-fluid pb-2" src={presidentData.img} alt="PresidentImage" style={{ width: "50%", height: "auto" }}/>
                                <h3>{presidentData.name}</h3>
                                <h4>(President)</h4>
                                <p>Society For Heritage, Archaeology &amp; Management</p>
                            </div>
                        </div>
                        <div className="col-md-7 d-flex align-items-center my-5">
                            <div style={{ color: Theming.textColourDark }}>
                                <h2 className="font-weight-bold my-3">ABOUT US</h2>
                                <p className="text-justified">
                                    The Society for Heritage Archaeology and Management (SHAM), is truly an academic body which aims to highlight India’s recent archaeological pursuits, her heritage treasures and their management related activities. This society will give a platform to the heritage enthusiasts affiliated with different disciplines such as science, technology, management and so on to discuss and research on various aspects of heritage, archaeology and management. It will also provide a scope to the eminent academicians, senior researchers and also to the budding researchers to exhibit their research through the
                                        <a rel="noopener noreferrer" target="_blank" href={RoutePath.journal}><i><strong> Journal of Heritage Archaeology and Management </strong></i></a>
                                        to be introduced as part of the society’s academic mission.
                                    </p>
                                <Link style={{ textDecoration: "none" }} to={RoutePath.teammembers}>Team Members</Link>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default AboutSection