import React from 'react';
import ReactMarkdown from 'react-markdown';
import { getLargeUrl } from '../../../helpers/image_helpers';
import MediaHelpers from '../../../helpers/media_file_helpers';
import DefaultProps from '../../../models/default_props';
import { EventModel } from '../../../models/event';
import EventService from '../../../services/event_service';
import PlaceholderImg from '../../templates/PlaceholderImg';
import PromiseLoader from '../../templates/PromiseLoader';
import DisplayImages from './displayImages';

class EventDetails extends PromiseLoader<EventModel, DefaultProps> {
    async promise() {
        const id = this.props.location.pathname.split('/')[2];
        return EventService.getEventById(id);
    };

    dataComponent(eventJson: EventModel) {
        return (
            <main className="my-5 py-5">
                <div className="container">

                    <section className="mt-4">

                        <div className="row">

                            <div className="col">

                                <div className="card mb-4 wow fadeIn">
                                    <PlaceholderImg
                                        style={{ maxHeight: "70vh" }}
                                        className="card-img d-block w-100"
                                        blurhash={eventJson.thumbnail.formats.blurhash}
                                        src={MediaHelpers.resolveMediaUrl(getLargeUrl(eventJson.thumbnail))} alt={eventJson.thumbnail.alternativeText} />
                                </div>

                                <div className="card mb-4 wow fadeIn">

                                    <div className="card-header font-weight-bold">
                                        <p className="h5 my-3">{eventJson.title}</p>
                                    </div>

                                    <div className="card-body mx-2">
                                        <footer className="blockquote-footer pb-2">
                                            <cite title="Source Title">{new Date(eventJson.date).toLocaleDateString("en-IN")}</cite>
                                        </footer>

                                        <ReactMarkdown>{eventJson.description}</ReactMarkdown>

                                    </div>
                                </div>

                                <div className="card card-comments mb-3 wow fadeIn">
                                    <div className="card-header font-weight-bold"><span className="px-2">More on the event</span></div>
                                    <div className="card-body">
                                        <DisplayImages images={eventJson.images} />
                                    </div>
                                </div>

                            </div>

                        </div>
                    </section>
                </div>
            </main>
        );
    }
}

export default EventDetails;