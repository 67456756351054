import React from 'react';
import { Link } from 'react-router-dom';
import RoutePath from '../../../constants/route-paths';
import Theming from '../../../constants/theming';
import { decodeRouteQuery, genRoutePath } from '../../../helpers/route_helpers';
import DefaultProps from '../../../models/default_props';
import { NewsletterModel, NewslettersResponse } from '../../../models/newsletter';
import NewsLetterService from '../../../services/newsletter_service';
import PromiseLoader from '../../templates/PromiseLoader';
import NewsletterCard from './newsletter_card';

const routeToPage = (page: number, search?: string) => search ?
    genRoutePath([RoutePath.newsletters], { search, page }) :
    genRoutePath([RoutePath.newsletters], { page });

class Newsletters extends PromiseLoader<NewslettersResponse, DefaultProps> {
    keyowrd?: string;

    promise = () => {
        const query = decodeRouteQuery(this.props.location);
        const currentPage = parseInt(query.page as string) || 1;

        return NewsLetterService.getNewsletters(currentPage, 6, query.search as string);
    };

    handleSearch = () => {
        const query = decodeRouteQuery(this.props.location);
        const searchQuery = query.search as string | undefined;

        if (this.keyowrd && this.keyowrd !== searchQuery)
            this.props.history.push(routeToPage(1, this.keyowrd));
    };

    onChangeKeyword: React.ChangeEventHandler<HTMLInputElement> = (e) => this.keyowrd = e.target.value;

    handleKeyDown: React.KeyboardEventHandler<HTMLInputElement> = (e) => {
        if (e.key === 'Enter')
            this.handleSearch();
    };

    render() {
        return (
            <main className="my-5 py-5">
                <div className="container" style={{ color: Theming.textColourDark }}>

                    <h2 className="h2-responsive"><span style={{ color: Theming.backgroundColourDark }}>SHAM</span> Newsletters</h2>
                    <hr className="my-3"></hr>

                    <div className="input-group mb-3">
                        <input id="search-input" type="text" className="form-control" placeholder="Enter search keyword" aria-describedby="search-button"
                            onChange={this.onChangeKeyword} onKeyDown={this.handleKeyDown} />

                        <div className="input-group-append">
                            <button type="button" className="input-group-text" id="search-button" onClick={this.handleSearch}>
                                <i className="fas fa-search"></i>
                            </button>
                        </div>
                    </div>
                    {super.render()}
                </div>
            </main>
        );
    }

    dataComponent(newslettersJson: NewslettersResponse) {
        return (
            <section className="text-center">
                <div className="row mb-4 wow fadeIn">
                    {this.displayEvents(newslettersJson.pageData)}
                </div>

                <nav className="d-flex justify-content-center" aria-label="Events navigation">
                    <ul className="pagination pg-blue">
                        {this.displayPagination(newslettersJson.totalPages, newslettersJson.page)}
                    </ul>
                </nav>
            </section>
        );
    }

    displayEvents = (newsletters: NewsletterModel[]) => {
        return newsletters.map(e => (
            <NewsletterCard newsletter={e} key={e.id} />
        ));
    };

    displayPagination = (totalPages: number, currentPage?: number) => {
        currentPage = currentPage || 1;
        const invalidPage = currentPage > totalPages || currentPage < 0 || currentPage === Math.abs(currentPage);

        let elements = [
            <li className={"page-item" + (currentPage === 1 || invalidPage ? " disabled" : "")} key="Previous">
                {
                    <Link to={routeToPage(currentPage - 1)} className="page-link" aria-label="Previous">
                        <span aria-hidden="true">&laquo;</span>
                        <span className="sr-only">Previous</span>
                    </Link>
                }
            </li>
        ];

        for (let i = 1; i <= totalPages; i++) {
            if (i === currentPage) {
                elements.push(
                    <li className="page-item active" key={i}>
                        <span className="page-link" style={{ backgroundColor: Theming.backgroundColourDark }}>
                            {i}
                            <span className="sr-only">(current)</span>
                        </span>
                    </li>
                );
            } else {
                elements.push(
                    <li className="page-item" key={i}>
                        <Link to={routeToPage(i, this.keyowrd)} className="page-link">{i}</Link>
                    </li>
                );
            }
        }
        elements.push(
            <li className={"page-item" + (currentPage === totalPages || invalidPage ? " disabled" : "")} key="Next">
                {
                    <Link to={routeToPage(currentPage + 1, this.keyowrd)} className="page-link" aria-label="Next">
                        <span aria-hidden="true">&raquo;</span>
                        <span className="sr-only">Next</span>
                    </Link>
                }
            </li>
        );

        return <ul className="pagination pg-blue justify-content-center">
            {elements}
        </ul>;
    };
}

export default Newsletters;