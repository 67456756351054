import CoreService from './core_service';
import RuntimeCache from './runtime_cache_service';

class GalleryService extends CoreService {
	static _instance: GalleryService;
	static getInstance = () => {
		if (!GalleryService._instance) {
			return GalleryService._instance = new GalleryService();
		}
		return GalleryService._instance;
	};

	cacheService: RuntimeCache;

	constructor() {
		super();
		this.cacheService = new RuntimeCache('galleries', false);
	}

	getGallery = async () => this.cacheService.getElseSet('all', async () => {
		const GalleryRes = await this._strapi.getEntries('galleries');

		return {
			Gallery: GalleryRes
		};
	});
}

export default GalleryService.getInstance();
