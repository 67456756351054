import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import RoutePath from '../../../constants/route-paths';
import Theming from '../../../constants/theming'

class ServicesSection extends Component {
    render() {
        return (
            <div className="container py-5 mb-5">
                    <section className="py-5" style={{ color: Theming.textColourDark }}>
                        <h3 className="font-weight-bold text-center pb-2">SERVICES</h3>
                        <p className="lead text-center pt-2 mb-5">List of Services we are providing</p>
                        <div className="row">
                            <div className="col-md-4">
                                <div className="card text-center text-white" style={{ backgroundColor: Theming.backgroundColourDark }}>
                                <div className="card-body">
                                    <a rel="noopener noreferrer" target="_blank" className="text-white" href={RoutePath.journal}>
                                        <p className="mt-4 pt-2"><img style={{ maxHeight: "90px" }} src='/icons/jham_no_bg.png' alt='' /></p>
                                        <h5 className="font-weight-normal my-4 py-2">Journal</h5>
                                    </a>
                                    <p className="mb-4">Read from our vast range of Journals available for free and members.</p>
                                </div>
                                </div>
                            </div>
                            <div className="col-md-4 ">
                                <div className="card text-center text-white" style={{ backgroundColor: Theming.backgroundColourLight }}>
                                <div className="card-body">
                                    <Link className="text-white" to={RoutePath.newsletters}>
                                        <p className="mt-4 pt-2"><img style={{ maxHeight: "90px" }} src='/icons/newsletter_icon.png' alt='' /></p>
                                        <h5 className="font-weight-normal my-4 py-2">Newsletters</h5>
                                    </Link>
                                    <p className="mb-4">Read about updates and events of our society from our newsletters.</p>
                                </div>
                                </div>
                            </div>
                            <div className="col-md-4 ">
                                <div className="card text-center text-white" style={{ backgroundColor: Theming.backgroundColourDark }}>
                                <div className="card-body">
                                    <Link className="text-white" to={RoutePath.membership}>
                                        <p className="mt-4 pt-2"><img style={{ maxHeight: "90px" }} src='/icons/member_icon.png' alt='' /></p>
                                        <h5 className="font-weight-normal my-4 py-2">Membership</h5>
                                    </Link>
                                    <p className="mb-4">Take our membership and enjoy unlimited books and journals here.</p>
                                </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
        );
    }
}

export default ServicesSection