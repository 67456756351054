import React from 'react';
import { Modal } from 'react-bootstrap';
import Theming from '../../constants/theming';
import './pdf_modal_style.css';

interface PdfDisplayModalProps {
    modalShow: boolean;
    toggleModal: (toggle: boolean) => void;
    docUrl: string;
}

function PdfDisplayModal(props: PdfDisplayModalProps) {
    return (
        <Modal
            show={props.modalShow}
            onHide={() => props.toggleModal(false)}
            centered
            dialogClassName="my-modal"
        >
            <Modal.Header className="text-uppercase" style={{ backgroundColor: Theming.backgroundColourLight, color: Theming.textColourLight }} closeButton>
                <Modal.Title>
                    SHAM Journal
                    </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <iframe
                    title="PDF"
                    src={`${props.docUrl}#toolbar=0`}
                    style={{ width: "100%", height: "80vh" }}
                />
            </Modal.Body>
        </Modal>
    );
};

export default PdfDisplayModal;