import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import RoutePath from '../../../constants/route-paths'
import Theming from '../../../constants/theming'
import * as auth from '../../../firebase/auth'
import DefaultProps from '../../../models/default_props'

interface InterfaceProps extends DefaultProps {
  email?: string;
  error?: any;
  password?: string;
  username?: string;
}

interface InterfaceState {
  email: string;
  error: any;
  password: string;
  username: string;
}
export class SignupForm extends Component<InterfaceProps, InterfaceState> {
  private static INITIAL_STATE = {
    email: "",
    error: null,
    password: "",
    username: ""
  };

  private static propKey(propertyName: string, value: any): object {
    return { [propertyName]: value };
  }

  constructor(props: InterfaceProps) {
    super(props);
    this.state = { ...SignupForm.INITIAL_STATE };
  }

  public onSubmit(event: any) {
    event.preventDefault();

    const { email, password, username } = this.state;

    auth.doCreateUserWithEmailAndPassword(email, password, username)
      .then(() => {
        this.setState(() => ({ ...SignupForm.INITIAL_STATE }));
        this.props.history.push(RoutePath.home);
      })
      .catch(error => {
        this.setState(SignupForm.propKey("error", error));
      });
  }

  clearFields(this: any) {
    this.setState(SignupForm.propKey("error", ''));
    this.setState(SignupForm.propKey("username", ''));
    this.setState(SignupForm.propKey("email", ''));
    this.setState(SignupForm.propKey("password", ''));
  }

  showErrorMessage(message: any) {
    return (
      <div className="alert alert-warning alert-dismissible fade show" role="alert">
        {message}
        <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => this.clearFields()}>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    );
  }
  render() {
    const { username, email, password, error } = this.state;

    const isInvalid =
      password === "" ||
      email === "" ||
      username === "";
    return (
      <div className="d-flex justify-content-center py-5 my-5">
        <div className="card" style={{ minWidth: "350px", maxWidth: "500px" }}>
          <h5 className="card-header text-uppercase text-center py-4" style={{ backgroundColor: Theming.backgroundColourLight, color: Theming.textColourLight }}>
            <strong>Sign up</strong>
          </h5>
          <div className="card-body px-lg-5 pt-0">
            <form className="text-center" onSubmit={(event) => this.onSubmit(event)} style={{ color: Theming.textColourDark }}>
              <div className="md-form">
                <input type="text" id="materialRegisterFormFirstName" className="form-control" style={{ color: Theming.textColourDark }} value={username} onChange={event => this.setStateWithEvent(event, "username")} />
                <label htmlFor="materialRegisterFormFirstName" style={{ color: Theming.textColourDark }}>Full Name</label>
              </div>
              <div className="md-form mt-0">
                <input type="email" id="materialRegisterFormEmail" className="form-control" style={{ color: Theming.textColourDark }} value={email} onChange={event => this.setStateWithEvent(event, "email")} />
                <label htmlFor="materialRegisterFormEmail" style={{ color: Theming.textColourDark }}>E-mail</label>
              </div>
              <div className="md-form">
                <input type="password" id="materialRegisterFormPassword" className="form-control" aria-describedby="materialRegisterFormPasswordHelpBlock" value={password} onChange={event => this.setStateWithEvent(event, "password")} />
                <label htmlFor="materialRegisterFormPassword" style={{ color: Theming.textColourDark }}>Password</label>

              </div>
              <button className="btn btn-block my-4 waves-effect z-depth-0" type="submit" style={{ borderColor: Theming.backgroundColourDark }} disabled={isInvalid}>Sign Up</button>
              <p>Already a member?
                                <Link to={RoutePath.login} style={{ textDecoration: "none", color: Theming.textColourDark }}> Sign In</Link>
              </p>
              <p>or sign in with:</p>
              <button type="button" className="btn btn-md" onClick={auth.doSignInUsingFb} style={{ color: "white", backgroundColor: "#3b5998" }}><i className="fab fa-facebook-f pr-1"></i> Facebook</button>
              <button type="button" className="btn btn-md" onClick={auth.doSignInUsingGoogle} style={{ color: "white", backgroundColor: "#dd4b39" }}><i className="fab fa-google"></i> Google</button>
              <hr />
              <p>By clicking
                                <em> Sign up</em> you agree to our
                                <Link to="/" target="_blank" style={{ textDecoration: "none" }}> terms of service</Link>
              </p>
              {error && this.showErrorMessage(error.message)}
            </form>
          </div>
        </div>
      </div>
    )
  }
  private setStateWithEvent(event: any, columnType: string) {
    this.setState(SignupForm.propKey(columnType, (event.target as any).value));
  }
}