import React, { Component } from 'react'
import Theming from '../../../constants/theming'

class MissionVission extends Component {
    render() {
        return (
            <div className="container-fluid py-5" style={{ paddingLeft: "40px", backgroundColor: Theming.backgroundColourLight }}>
                <section className="container" style={{ color: Theming.textColourDark }}>
                    <div className="row pr-lg-5">
                        <div className="col-md-8 d-flex align-items-center">
                            <div>
                                <h2 className="font-weight-bold mb-4">MISSION &amp; VISION</h2>
                                <ol>
                                    <li>The mission of the Society for Heritage Archaeology and Management (SHAM) is to focus on heritage studies, archaeological works and their importance in the society from various perspectives.</li>
                                    <li>SHAM aims to enhance the knowledge regarding the rich heritage, culture and tradition through various interactions, nationally and globally.</li>
                                    <li>To promote our mission, we would like to organize various activities like seminar, conferences, workshops, field surveys, cultural programmes and projects.</li>
                                    <li>For promoting heritage and archaeological programmes, we would introduce public Archaeology as a tool.</li>
                                    <li>In archaeological pursuits, we would take help from different academic streams like Metal &amp; Metallurgy, Geography, Geo-morphology, Hydrology, Environmental science, Heritage Management, Civil engineering and Architecture. These academic disciplines will enrich our knowledge and vision to understand the present day archaeological and heritage studies both national and global perspectives.</li>
                                </ol>
                            </div>
                        </div>
                        <div className="col-md-4 mb-4">
                            <div className="view">
                                <img className="img-fluid pt-5" src="/img/only_logo.png" alt="sampleImage" style={{ width: "110%", height: "auto"}}/>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default MissionVission