import { TeamMember, TeamMemberType } from '../models/team_member';
import CoreService from './core_service';
import RuntimeCache from './runtime_cache_service';

class TeamMemberService extends CoreService {
    static _instance: TeamMemberService;
    static getInstance = () => {
        if (!TeamMemberService._instance) {
            return TeamMemberService._instance = new TeamMemberService();
        }
        return TeamMemberService._instance;
    };

    cacheService: RuntimeCache;

    constructor() {
        super();
        this.cacheService = new RuntimeCache('team-member', false);
    }

    getTeamMembersByType = () => {
        return this.cacheService.getElseSet<TeamMemberType[]>(
            'team-member-types',
            async () => {
                const res = await this._strapi.getEntries('team-member-types') as TeamMemberType[];

                res.forEach(val => {
                    val.team_members.forEach(member => this.cacheService.set(member.id, member));
                });

                return res;
            }
        );
    };

    getMemberById = (id: string) => this.cacheService.getElseSet<TeamMember>(id, () => {
        return this._strapi.getEntry('team-members', id) as Promise<TeamMember>;
    });
}

export default TeamMemberService.getInstance();
