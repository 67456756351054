import path from 'path';
import qs from 'querystring';

export function genRoutePath(route: string[], query?: { [key: string]: string | number | string[] | number[] }) {
	const routePath = route.reduce((prev, cur) => path.join(prev, cur));
	return routePath + "?" + qs.stringify(query);
}

export function decodeRouteQuery(location:Location) {
	return qs.parse(location.search.substr(1));
}