import React from 'react';
import AsyncSnapshot from '../../models/async_snapshot';

type BuilderFunc<T> = (snapshot: AsyncSnapshot<T>) => JSX.Element;

interface PromiseBuilderState<T> {
	snap: AsyncSnapshot<T>;
}

function PromiseBuilder<T>(promise: Promise<T>, builder: BuilderFunc<T>) {
	class Component extends React.Component<{}, PromiseBuilderState<T>> {
		_ismounted: boolean;

		constructor(props: {}) {
			super(props);
			this.state = { snap: new AsyncSnapshot<T>("loading", undefined, undefined) };
			this._ismounted = false;
		}

		updateStateIfMounted = (state: PromiseBuilderState<T>) => {
			if (this._ismounted)
				this.setState(state);
		}

		componentDidMount() {
			this._ismounted = true;
			promise
				.then(val => this.updateStateIfMounted({ snap: new AsyncSnapshot<T>("complete", undefined, val) }))
				.catch(error => this.updateStateIfMounted({ snap: new AsyncSnapshot<T>("failed", error, undefined) }));
		}

		componentWillUnmount() {
			this._ismounted = false;
		}

		render() {
			return (
				builder(this.state.snap)
			)
		}
	}

	return <Component />;
}

export default PromiseBuilder;