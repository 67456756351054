import React, { useContext } from "react";
import RoutePath from "../../../../constants/route-paths";
import Theming from "../../../../constants/theming";
import * as auth from "../../../../firebase/auth";
import DefaultProps from "../../../../models/default_props";
import AppContext from "../../../app-context";

const Logout = (props: DefaultProps) => {
  const appContext = useContext(AppContext);

  const continueLogout = async () => {
    await auth.doSignOut();
    appContext.updateUser(null);
    return props.history.push(RoutePath.home);
  }

  return (
    <>
      <div className="jumbotron card card-image" style={{ backgroundColor: "white" }}>
        <div className="text-white text-center px-4">
          <div className="py-4" style={{ color: Theming.textColourDark, borderColor: Theming.textColourDark }}>
            <h3 className="card-title h1-responsive py-5 mb-5 font-bold"><strong>You Are About to Logout</strong></h3>
            <button className="btn btn-md" onClick={continueLogout} style={{ color: Theming.textColourDark, borderColor: Theming.textColourDark }}>Logout</button>
          </div>
        </div>
      </div>
      <br /><br />
    </>
  );
}

export default Logout;