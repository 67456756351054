import React from "react";
import GalleryBuilder from './gallery_builder';
import { Image } from '../../../models/image';
import { GalleryItem } from '../../../models/gallery';

const DisplayImages = (props: { images: GalleryItem[]; }) => {
    const images: Image[] = props.images.map(img => {
        img.picture.caption = img.name;
        return img.picture;
    });

    return (
        <GalleryBuilder images={images} />
    );
};

export default DisplayImages;