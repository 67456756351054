export const executiveCommiteeMembers: any = [
    {
        _id: "Prof-Durga-Basu",
        designation: "President",
        subDesignation: "Former Professor & HOD, Dept. of Archaeology, C.U.",
        name: "Prof. Durga Basu",
        img: "/img/teammembers/ProfDurgaBasu.jpg",
        description: "Prof. Durga Basu is Professor of Department of Archaeology, Calcutta University and Former Syndicate and Senatemember of the same University. She has specialized in Indian art & architecture , Buddhist studies and Historical archaeology. She has Published more forty articles in various journals of National & International repute and in the edited books. She has also Published a monograph entitled “The N.B.P. Culture of Eastern India”.Dr. Basu has participated and presented various research papers in a number of International conferences held in Ireland, Jordan, Cambodia, Manila, Iran, Vietnam, Sri Lanka ,Bangladesah, Sweden, Paris, and Cardiff University, Wales.She has chaired in the Academic sessions of International Congress of Society of South Asian Archaeology, held in Siraz Iran (2007),Sri Lanka(2010); in World Archaeology Congress-7 held In Jordon and in 15th EurASEAA on “ Visuality, Connectivity and sustainability of Cultral heritage of Cambodoa” in Paris in 2015. As a Director of Excavation, conducted Excavations at Kankan Dighi, 2013-2015 and also actively participated Participated in many excavations held at Rajbadidanga(1981); Mongalkot(1986); Dihar(2009); Moghalmari(2007-2012) in West Bengal. Very recently BAR International Series 2741, Oxford has published her article on” Public Archaeology, Its Scope and Limitation in Regional Aspects” in Current Trends in Archaeological Heritage Preservation: National & International Perspectives edited by- Sergiu Musteata. She is also the guest Faulty in Indology & Art Appreciation course of R.K.M. Institute of Culture. She is the editor of the present volume."
    },
    {
        _id: "Dr-Banani-Bhattacharya",
        designation: "Vice-President",
        subDesignation: "Deputy Director, Haryana State Archaeology & Museum",
        name: "Dr. Banani Bhattacharya",
        img: "https://dummyimage.com/260x344",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec aliquet orci nec enim dignissim auctor. Curabitur tincidunt, nisi ac sodales auctor, quam sem tristique mauris, ut tristique sem justo vitae mi. Nulla egestas elementum sagittis. Proin pellentesque, diam eu ultricies laoreet, sem nisi tempus felis, quis congue mi velit vel orci. Sed at purus ac ipsum egestas pretium. Nullam feugiat orci quis orci mattis posuere. In hac habitasse platea dictumst. Interdum et malesuada fames ac ante ipsum primis in faucibus. Pellentesque tincidunt nec sem sed tempus. Proin vitae ullamcorper ligula. Praesent lobortis ornare purus ut facilisis. In tortor ipsum, ultricies in varius eget, hendrerit sed leo. Suspendisse massa elit, accumsan id ante in, posuere porta metus. Maecenas nec magna dolor. In accumsan a velit in ultrices."
    },
    {
        _id: "Dr-Prabir-Biswas",
        designation: "Vice-President",
        subDesignation: "Principal, Hari Mohan Ghose College, Kolkata",
        name: "Dr. Prabir Biswas",
        img: "https://dummyimage.com/260x344",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec aliquet orci nec enim dignissim auctor. Curabitur tincidunt, nisi ac sodales auctor, quam sem tristique mauris, ut tristique sem justo vitae mi. Nulla egestas elementum sagittis. Proin pellentesque, diam eu ultricies laoreet, sem nisi tempus felis, quis congue mi velit vel orci. Sed at purus ac ipsum egestas pretium. Nullam feugiat orci quis orci mattis posuere. In hac habitasse platea dictumst. Interdum et malesuada fames ac ante ipsum primis in faucibus. Pellentesque tincidunt nec sem sed tempus. Proin vitae ullamcorper ligula. Praesent lobortis ornare purus ut facilisis. In tortor ipsum, ultricies in varius eget, hendrerit sed leo. Suspendisse massa elit, accumsan id ante in, posuere porta metus. Maecenas nec magna dolor. In accumsan a velit in ultrices."
    },
    {
        _id: "Mr-Anustup-Chatterjee",
        designation: "Secretary",
        subDesignation: "Asst. Professor, Mechanical Engg Dept, Techno International Newtown",
        name: "Mr. Anustup Chatterjee",
        img: "https://dummyimage.com/260x344",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec aliquet orci nec enim dignissim auctor. Curabitur tincidunt, nisi ac sodales auctor, quam sem tristique mauris, ut tristique sem justo vitae mi. Nulla egestas elementum sagittis. Proin pellentesque, diam eu ultricies laoreet, sem nisi tempus felis, quis congue mi velit vel orci. Sed at purus ac ipsum egestas pretium. Nullam feugiat orci quis orci mattis posuere. In hac habitasse platea dictumst. Interdum et malesuada fames ac ante ipsum primis in faucibus. Pellentesque tincidunt nec sem sed tempus. Proin vitae ullamcorper ligula. Praesent lobortis ornare purus ut facilisis. In tortor ipsum, ultricies in varius eget, hendrerit sed leo. Suspendisse massa elit, accumsan id ante in, posuere porta metus. Maecenas nec magna dolor. In accumsan a velit in ultrices."
    },
    {
        _id: "Ms-Asmita-Basu-Chatterjee",
        designation: "Treasurer",
        subDesignation: "Asst. Professor (Finance), Army Institute of Management",
        name: "Ms. Asmita Basu Chatterjee",
        img: "https://dummyimage.com/260x344",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec aliquet orci nec enim dignissim auctor. Curabitur tincidunt, nisi ac sodales auctor, quam sem tristique mauris, ut tristique sem justo vitae mi. Nulla egestas elementum sagittis. Proin pellentesque, diam eu ultricies laoreet, sem nisi tempus felis, quis congue mi velit vel orci. Sed at purus ac ipsum egestas pretium. Nullam feugiat orci quis orci mattis posuere. In hac habitasse platea dictumst. Interdum et malesuada fames ac ante ipsum primis in faucibus. Pellentesque tincidunt nec sem sed tempus. Proin vitae ullamcorper ligula. Praesent lobortis ornare purus ut facilisis. In tortor ipsum, ultricies in varius eget, hendrerit sed leo. Suspendisse massa elit, accumsan id ante in, posuere porta metus. Maecenas nec magna dolor. In accumsan a velit in ultrices."
    },{
        _id: "Dr-Kallol-Dasgupta",
        designation: "Joint Treasurer",
        subDesignation: "Faculty, Dept. of Ancient Indian History, Sanskrit University, Kolkata",
        name: "Dr. Kallol Dasgupta",
        img: "https://dummyimage.com/260x344",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec aliquet orci nec enim dignissim auctor. Curabitur tincidunt, nisi ac sodales auctor, quam sem tristique mauris, ut tristique sem justo vitae mi. Nulla egestas elementum sagittis. Proin pellentesque, diam eu ultricies laoreet, sem nisi tempus felis, quis congue mi velit vel orci. Sed at purus ac ipsum egestas pretium. Nullam feugiat orci quis orci mattis posuere. In hac habitasse platea dictumst. Interdum et malesuada fames ac ante ipsum primis in faucibus. Pellentesque tincidunt nec sem sed tempus. Proin vitae ullamcorper ligula. Praesent lobortis ornare purus ut facilisis. In tortor ipsum, ultricies in varius eget, hendrerit sed leo. Suspendisse massa elit, accumsan id ante in, posuere porta metus. Maecenas nec magna dolor. In accumsan a velit in ultrices."
    },{
        _id: "Dr-Biswajit-Roy",
        designation: "Executive Secretary for Geo- Archaeology & Digital Archive",
        subDesignation: "Assistant Professor and HOD, Department of Geography, Pijush Kanti Mukherjee Mahavidyalaya, Alipurduar",
        name: "Dr. Biswajit Roy",
        img: "https://dummyimage.com/260x344",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec aliquet orci nec enim dignissim auctor. Curabitur tincidunt, nisi ac sodales auctor, quam sem tristique mauris, ut tristique sem justo vitae mi. Nulla egestas elementum sagittis. Proin pellentesque, diam eu ultricies laoreet, sem nisi tempus felis, quis congue mi velit vel orci. Sed at purus ac ipsum egestas pretium. Nullam feugiat orci quis orci mattis posuere. In hac habitasse platea dictumst. Interdum et malesuada fames ac ante ipsum primis in faucibus. Pellentesque tincidunt nec sem sed tempus. Proin vitae ullamcorper ligula. Praesent lobortis ornare purus ut facilisis. In tortor ipsum, ultricies in varius eget, hendrerit sed leo. Suspendisse massa elit, accumsan id ante in, posuere porta metus. Maecenas nec magna dolor. In accumsan a velit in ultrices."
    },{
        _id: "Dr-Sonali-Saha-Bhattacharya",
        designation: "Executive Secretary for Geography & Geo-morphology",
        subDesignation: "Former Dy. Director, NATMO",
        name: "Dr. Sonali Saha Bhattacharya",
        img: "https://dummyimage.com/260x344",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec aliquet orci nec enim dignissim auctor. Curabitur tincidunt, nisi ac sodales auctor, quam sem tristique mauris, ut tristique sem justo vitae mi. Nulla egestas elementum sagittis. Proin pellentesque, diam eu ultricies laoreet, sem nisi tempus felis, quis congue mi velit vel orci. Sed at purus ac ipsum egestas pretium. Nullam feugiat orci quis orci mattis posuere. In hac habitasse platea dictumst. Interdum et malesuada fames ac ante ipsum primis in faucibus. Pellentesque tincidunt nec sem sed tempus. Proin vitae ullamcorper ligula. Praesent lobortis ornare purus ut facilisis. In tortor ipsum, ultricies in varius eget, hendrerit sed leo. Suspendisse massa elit, accumsan id ante in, posuere porta metus. Maecenas nec magna dolor. In accumsan a velit in ultrices."
    },{
        _id: "Dr-Debasis-Mondal",
        designation: "Executive Secretary for Anthropology",
        subDesignation: "Assistant Professor, Dept. of Anthropology, C.U.",
        name: "Dr. Debasis Mondal",
        img: "https://dummyimage.com/260x344",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec aliquet orci nec enim dignissim auctor. Curabitur tincidunt, nisi ac sodales auctor, quam sem tristique mauris, ut tristique sem justo vitae mi. Nulla egestas elementum sagittis. Proin pellentesque, diam eu ultricies laoreet, sem nisi tempus felis, quis congue mi velit vel orci. Sed at purus ac ipsum egestas pretium. Nullam feugiat orci quis orci mattis posuere. In hac habitasse platea dictumst. Interdum et malesuada fames ac ante ipsum primis in faucibus. Pellentesque tincidunt nec sem sed tempus. Proin vitae ullamcorper ligula. Praesent lobortis ornare purus ut facilisis. In tortor ipsum, ultricies in varius eget, hendrerit sed leo. Suspendisse massa elit, accumsan id ante in, posuere porta metus. Maecenas nec magna dolor. In accumsan a velit in ultrices."
    },{
        _id: "Dr-Abhijit-Patra",
        designation: "Executive Secretary for Metal & Metallurgy",
        subDesignation: "Associate Professor, Mechanical Engg Dept, Techno International Newtown",
        name: "Dr. Abhijit Patra",
        img: "https://dummyimage.com/260x344",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec aliquet orci nec enim dignissim auctor. Curabitur tincidunt, nisi ac sodales auctor, quam sem tristique mauris, ut tristique sem justo vitae mi. Nulla egestas elementum sagittis. Proin pellentesque, diam eu ultricies laoreet, sem nisi tempus felis, quis congue mi velit vel orci. Sed at purus ac ipsum egestas pretium. Nullam feugiat orci quis orci mattis posuere. In hac habitasse platea dictumst. Interdum et malesuada fames ac ante ipsum primis in faucibus. Pellentesque tincidunt nec sem sed tempus. Proin vitae ullamcorper ligula. Praesent lobortis ornare purus ut facilisis. In tortor ipsum, ultricies in varius eget, hendrerit sed leo. Suspendisse massa elit, accumsan id ante in, posuere porta metus. Maecenas nec magna dolor. In accumsan a velit in ultrices."
    }

]

export const advisoryBoard: any = [
    {
        _id: "Dr-Sergiu-Musteata",
        designation: "Professor, History and Geography Faculty, Ion Creanga State University, Republic of Moldova",
        name: "Prof. (Dr.) Sergiu Musteata",
        img: "https://dummyimage.com/260x344",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec aliquet orci nec enim dignissim auctor. Curabitur tincidunt, nisi ac sodales auctor, quam sem tristique mauris, ut tristique sem justo vitae mi. Nulla egestas elementum sagittis. Proin pellentesque, diam eu ultricies laoreet, sem nisi tempus felis, quis congue mi velit vel orci. Sed at purus ac ipsum egestas pretium. Nullam feugiat orci quis orci mattis posuere. In hac habitasse platea dictumst. Interdum et malesuada fames ac ante ipsum primis in faucibus. Pellentesque tincidunt nec sem sed tempus. Proin vitae ullamcorper ligula. Praesent lobortis ornare purus ut facilisis. In tortor ipsum, ultricies in varius eget, hendrerit sed leo. Suspendisse massa elit, accumsan id ante in, posuere porta metus. Maecenas nec magna dolor. In accumsan a velit in ultrices."
    },{
        _id: "Dr-Anura-Manatunga",
        designation: "Director, Dept. of Archaeology, Srilanka",
        name: "Dr. Anura Manatunga",
        img: "https://dummyimage.com/260x344",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec aliquet orci nec enim dignissim auctor. Curabitur tincidunt, nisi ac sodales auctor, quam sem tristique mauris, ut tristique sem justo vitae mi. Nulla egestas elementum sagittis. Proin pellentesque, diam eu ultricies laoreet, sem nisi tempus felis, quis congue mi velit vel orci. Sed at purus ac ipsum egestas pretium. Nullam feugiat orci quis orci mattis posuere. In hac habitasse platea dictumst. Interdum et malesuada fames ac ante ipsum primis in faucibus. Pellentesque tincidunt nec sem sed tempus. Proin vitae ullamcorper ligula. Praesent lobortis ornare purus ut facilisis. In tortor ipsum, ultricies in varius eget, hendrerit sed leo. Suspendisse massa elit, accumsan id ante in, posuere porta metus. Maecenas nec magna dolor. In accumsan a velit in ultrices."
    },{
        _id: "Mr-Edoardo-Bedin",
        designation: "MA in Managing Archaeological Sites, Visitor Services Supervisor – Operations, Haddo Haddo, National Trust for Scotland",
        name: "Mr. Edoardo Bedin",
        img: "https://dummyimage.com/260x344",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec aliquet orci nec enim dignissim auctor. Curabitur tincidunt, nisi ac sodales auctor, quam sem tristique mauris, ut tristique sem justo vitae mi. Nulla egestas elementum sagittis. Proin pellentesque, diam eu ultricies laoreet, sem nisi tempus felis, quis congue mi velit vel orci. Sed at purus ac ipsum egestas pretium. Nullam feugiat orci quis orci mattis posuere. In hac habitasse platea dictumst. Interdum et malesuada fames ac ante ipsum primis in faucibus. Pellentesque tincidunt nec sem sed tempus. Proin vitae ullamcorper ligula. Praesent lobortis ornare purus ut facilisis. In tortor ipsum, ultricies in varius eget, hendrerit sed leo. Suspendisse massa elit, accumsan id ante in, posuere porta metus. Maecenas nec magna dolor. In accumsan a velit in ultrices."
    },{
        _id: "Dr-Ranjana-Ray",
        designation: "Emeritus Professor, Dept. of Anthropology, Calcutta University",
        name: "Prof. (Dr.) Ranjana Ray",
        img: "https://dummyimage.com/260x344",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec aliquet orci nec enim dignissim auctor. Curabitur tincidunt, nisi ac sodales auctor, quam sem tristique mauris, ut tristique sem justo vitae mi. Nulla egestas elementum sagittis. Proin pellentesque, diam eu ultricies laoreet, sem nisi tempus felis, quis congue mi velit vel orci. Sed at purus ac ipsum egestas pretium. Nullam feugiat orci quis orci mattis posuere. In hac habitasse platea dictumst. Interdum et malesuada fames ac ante ipsum primis in faucibus. Pellentesque tincidunt nec sem sed tempus. Proin vitae ullamcorper ligula. Praesent lobortis ornare purus ut facilisis. In tortor ipsum, ultricies in varius eget, hendrerit sed leo. Suspendisse massa elit, accumsan id ante in, posuere porta metus. Maecenas nec magna dolor. In accumsan a velit in ultrices."
    },{
        _id: "Dr-Vibha-Tripathi",
        designation: "Former Professor & HOD, Dept. of Ancient Indian History & Archaeology, Benaras Hindu University",
        name: "Prof. (Dr.) Vibha Tripathi",
        img: "https://dummyimage.com/260x344",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec aliquet orci nec enim dignissim auctor. Curabitur tincidunt, nisi ac sodales auctor, quam sem tristique mauris, ut tristique sem justo vitae mi. Nulla egestas elementum sagittis. Proin pellentesque, diam eu ultricies laoreet, sem nisi tempus felis, quis congue mi velit vel orci. Sed at purus ac ipsum egestas pretium. Nullam feugiat orci quis orci mattis posuere. In hac habitasse platea dictumst. Interdum et malesuada fames ac ante ipsum primis in faucibus. Pellentesque tincidunt nec sem sed tempus. Proin vitae ullamcorper ligula. Praesent lobortis ornare purus ut facilisis. In tortor ipsum, ultricies in varius eget, hendrerit sed leo. Suspendisse massa elit, accumsan id ante in, posuere porta metus. Maecenas nec magna dolor. In accumsan a velit in ultrices."
    },{
        _id: "Dr-Alok-Tripathi",
        designation: "Professor, Dept. of History, Assam University, Silchar",
        name: "Prof. (Dr.) Alok Tripathi",
        img: "https://dummyimage.com/260x344",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec aliquet orci nec enim dignissim auctor. Curabitur tincidunt, nisi ac sodales auctor, quam sem tristique mauris, ut tristique sem justo vitae mi. Nulla egestas elementum sagittis. Proin pellentesque, diam eu ultricies laoreet, sem nisi tempus felis, quis congue mi velit vel orci. Sed at purus ac ipsum egestas pretium. Nullam feugiat orci quis orci mattis posuere. In hac habitasse platea dictumst. Interdum et malesuada fames ac ante ipsum primis in faucibus. Pellentesque tincidunt nec sem sed tempus. Proin vitae ullamcorper ligula. Praesent lobortis ornare purus ut facilisis. In tortor ipsum, ultricies in varius eget, hendrerit sed leo. Suspendisse massa elit, accumsan id ante in, posuere porta metus. Maecenas nec magna dolor. In accumsan a velit in ultrices."
    },{
        _id: "Dr-Malay-Mukhopadhyay",
        designation: "Professor, Dept. of Geography, Visva Bharati",
        name: "Prof. (Dr.) Malay Mukhopadhyay",
        img: "https://dummyimage.com/260x344",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec aliquet orci nec enim dignissim auctor. Curabitur tincidunt, nisi ac sodales auctor, quam sem tristique mauris, ut tristique sem justo vitae mi. Nulla egestas elementum sagittis. Proin pellentesque, diam eu ultricies laoreet, sem nisi tempus felis, quis congue mi velit vel orci. Sed at purus ac ipsum egestas pretium. Nullam feugiat orci quis orci mattis posuere. In hac habitasse platea dictumst. Interdum et malesuada fames ac ante ipsum primis in faucibus. Pellentesque tincidunt nec sem sed tempus. Proin vitae ullamcorper ligula. Praesent lobortis ornare purus ut facilisis. In tortor ipsum, ultricies in varius eget, hendrerit sed leo. Suspendisse massa elit, accumsan id ante in, posuere porta metus. Maecenas nec magna dolor. In accumsan a velit in ultrices."
    },{
        _id: "Dr-Neera-Mishra",
        designation: "Chairperson-Trustee, Draupadi Dream Trust",
        name: "Dr. Neera Mishra",
        img: "https://dummyimage.com/260x344",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec aliquet orci nec enim dignissim auctor. Curabitur tincidunt, nisi ac sodales auctor, quam sem tristique mauris, ut tristique sem justo vitae mi. Nulla egestas elementum sagittis. Proin pellentesque, diam eu ultricies laoreet, sem nisi tempus felis, quis congue mi velit vel orci. Sed at purus ac ipsum egestas pretium. Nullam feugiat orci quis orci mattis posuere. In hac habitasse platea dictumst. Interdum et malesuada fames ac ante ipsum primis in faucibus. Pellentesque tincidunt nec sem sed tempus. Proin vitae ullamcorper ligula. Praesent lobortis ornare purus ut facilisis. In tortor ipsum, ultricies in varius eget, hendrerit sed leo. Suspendisse massa elit, accumsan id ante in, posuere porta metus. Maecenas nec magna dolor. In accumsan a velit in ultrices."
    },{
        _id: "Dr-Adam-Hardy",
        designation: "Emeritus Professor of Asian Architecture, Welsh School of Architecture, Cardiff University ",
        name: "Prof. (Dr.) Adam Hardy",
        img: "https://dummyimage.com/260x344",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec aliquet orci nec enim dignissim auctor. Curabitur tincidunt, nisi ac sodales auctor, quam sem tristique mauris, ut tristique sem justo vitae mi. Nulla egestas elementum sagittis. Proin pellentesque, diam eu ultricies laoreet, sem nisi tempus felis, quis congue mi velit vel orci. Sed at purus ac ipsum egestas pretium. Nullam feugiat orci quis orci mattis posuere. In hac habitasse platea dictumst. Interdum et malesuada fames ac ante ipsum primis in faucibus. Pellentesque tincidunt nec sem sed tempus. Proin vitae ullamcorper ligula. Praesent lobortis ornare purus ut facilisis. In tortor ipsum, ultricies in varius eget, hendrerit sed leo. Suspendisse massa elit, accumsan id ante in, posuere porta metus. Maecenas nec magna dolor. In accumsan a velit in ultrices."
    },{
        _id: "Dr-Subhas-Ganguly",
        designation: "Assistant Professor, Dept.of Metallurgical and Materials Engineering, National Institute of Technology Raipur",
        name: "Dr. Subhas Ganguly",
        img: "https://dummyimage.com/260x344",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec aliquet orci nec enim dignissim auctor. Curabitur tincidunt, nisi ac sodales auctor, quam sem tristique mauris, ut tristique sem justo vitae mi. Nulla egestas elementum sagittis. Proin pellentesque, diam eu ultricies laoreet, sem nisi tempus felis, quis congue mi velit vel orci. Sed at purus ac ipsum egestas pretium. Nullam feugiat orci quis orci mattis posuere. In hac habitasse platea dictumst. Interdum et malesuada fames ac ante ipsum primis in faucibus. Pellentesque tincidunt nec sem sed tempus. Proin vitae ullamcorper ligula. Praesent lobortis ornare purus ut facilisis. In tortor ipsum, ultricies in varius eget, hendrerit sed leo. Suspendisse massa elit, accumsan id ante in, posuere porta metus. Maecenas nec magna dolor. In accumsan a velit in ultrices."
    }
]

export const findMemberById = (memberId: string) => executiveCommiteeMembers.filter((member: any) => member._id === memberId).length === 0 ? advisoryBoard.filter((member: any) => member._id === memberId) : executiveCommiteeMembers.filter((member: any) => member._id === memberId)

export const findMemberByDesignation = (designation: string) => executiveCommiteeMembers.filter((member: any) => member.designation === designation)