const runtimeCache: { [key: string]: any; } = {};
const resetTopics: string[] = [];

class RuntimeCache {
    static resetAll = () => {
        resetTopics.forEach(topic => {
            for (const key in runtimeCache[topic])
                runtimeCache[topic][key] = null;
        });
    };

    cache: { [key: string]: any; };

    constructor(topic: string, reset: boolean = true) {
        if (!runtimeCache[topic])
            runtimeCache[topic] = {};
        if(reset)
            resetTopics.push(topic);
        this.cache = runtimeCache[topic];
    }

    get = <T = any>(key: string) => this.cache[key] as T;

    getElseSet = async <T = any>(key: string, operation: () => Promise<T>) => {
        if (!this.cache[key])
            this.cache[key] = await operation();

        return this.cache[key] as T;
    };

    set = (key: string, value: any) => this.cache[key] = value;

    reset = () => this.cache = {};
}

export default RuntimeCache;
